import {db} from "../../db";
import { getDocs, query, collectionGroup } from "firebase/firestore";

export default {

    state(){
        return{
            items: [],
        }
    },
    actions:{
        async getExchanges({commit}){
        const exchangequery = query(collectionGroup(db,"exchanges"));
        const snapshot = await getDocs(exchangequery);

        const exchanges = snapshot.docs.map(doc => {
            const data =  doc.data();
            const id = doc.id;
            return {id, ...data};
        })

        commit("setExchanges",exchanges);
            
        }
    },
    mutations:{
        setExchanges(state, exchanges){
            state.items = exchanges;
        }
    }

}