import { useToast } from "vue-toastification"
const toast = useToast();

export default{
    namespaced: true,
    actions: {
        success(_, message){
            toast.success(message); 
        },
        error(_, message){
            if(message.includes("wrong-password")){
                message = "Email oder Passwort ist leider nicht korrekt"
            }
            else if(message.includes("invalid-email")){
                message = "Die Email Adresse ist leider nicht korrekt"
            }
            else if(message.includes("auth/internal-error")){
                message = "Authenifizierung mit diesen Angaben fehlgeschlagen"
            }
            else if (message.includes("user-not-found")){
                message = "Die Email Adresse konnte nicht gefunden werden"
            }
            else if (message.includes("network-request-failed")){
                message = "Netzwerkanfrage fehlerhaft. Ist das Internet an?"
            }
            else if (message.includes("auth/weak-password")){
                message = "Das Passwort muß mindestens 6 Zeichen haben"
            }
            else if (message.includes("email-already-in-use")){
                message = "Diese Emailadresse wurde bereits registriert"
            }
            toast.error(message); 
        }
    }
}