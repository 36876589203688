
<template>
  
  <div class="grid-padding">
    <intro-modal />
      <div class="grid-container" >
          <div v-for ="item in items" :key="item.id">
            <div class="flexItem day-grid-container" >
                <Popover 
                  placement="right-start"
                  :item=item 
                />
            </div>  
            
          </div>

      </div>      
    </div>  
</template>

<script>

import Popover from "../components/PopoverCalenderday";
import calenderCore from "../composition/calenderCore";
import { useStore } from "vuex";
import { computed } from "vue";
import IntroModal from '../components/IntroModal.vue';

export default{

  components:{
    Popover,
    IntroModal
  },

  data(){
    return {
      insertEventisOpen: false,
      deleteEventisOpen: false,
      selectedDate: "",
      selectedEvent: "",
      deleteBackgroundColor: "#00ff00",
      calenderCore1 : calenderCore(),
      
    }
  },

 
  computed:{

      items(){
            return this.$store.state.calender.items;
        },
        events(){
            return this.$store.state.events.items;
        }
      },

      async created(){
        this.getCurrentDate();
        this.$store.dispatch("getCalender");
        this.$store.dispatch("getPublicHolidays"); 
        this.$store.dispatch("getShoolholidays"); 
        this.$store.dispatch("getContent"); 
          
        const calenderCore1 = calenderCore();
        calenderCore1.collectSpecialHolidays();
        calenderCore1.updateCalender();

        const store = useStore();
        const isAuthenticated = computed(() => store.getters["user/isAuthenticated"]);

        if(isAuthenticated.value == true){
          this.$store.state.settings.Authenticated = true
          if(this.$store.state.settings.fetchedDataFromDb == false){
            this.$store.dispatch("getEvents"); 
            await this.$store.dispatch("getEventsFromDB");
            this.$store.state.settings.fetchedDataFromDb = true;
            this.$store.state.events.items = this.$store.state.storedevents.items
            calenderCore1.updateCalender();
            this.$store.state.settings.startPageOpen = false;
          }
          else{
            this.$store.state.events.items = this.$store.state.storedevents.items
            calenderCore1.updateCalender();
          }
        }
        else{
          this.$store.dispatch("getEvents"); 
          calenderCore1.updateCalender();
          this.$store.state.settings.introPageToShow = 1;
          this.$store.state.settings.startPageOpen = true;
        }

      },

       async mounted() {
        window.addEventListener('resize', this.getDimensions);
        document.addEventListener( "keydown", this.onKeydown );
        document.addEventListener( "keyup", this.onKeyup );
      },

      unmounted() {
        window.removeEventListener('resize', this.getDimensions);
      },

      methods: {

        getCurrentDate(){
          var today = new Date();
          var dd = String(today.getDate()).padStart(2, '0');
          var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
          var yyyy = today.getFullYear();

          today = yyyy + '-' + mm + '-' + dd ;
          //2023-04-01
          this.$store.state.settings.currentRealDate = today;
          

        },

        getDimensions() {
          this.$store.dispatch("getCalender"); 
        },

        onKeyup (e) {
          if(e.key == 'd'){
          this.$store.state.settings.ctrlPressed = false;
          this.$store.state.settings.selectedCursor = 'default'
          
        }
        },

        onKeydown  (e) {
        if(e.key == 'd'){
          this.$store.state.settings.ctrlPressed = true;
          this.$store.state.settings.selectedCursor = 'grab'
          
        }
      }      
    }
  }

</script>

<style scoped>

@media (min-width: 100px) {
  .grid-container {
    
    grid-template-columns: repeat(3, 1fr); 
    border-radius: 20px;
    border-width: 10px;
    border-color: #4610e7;
    display: grid;
    width: 100%;
    height: 91vh;
    user-select: none;
    background-color: #FFFFFF;
    padding: 0px;
    justify-content: center;
  }
}

@media (min-width: 700px) {
  .grid-container {
    
    grid-template-columns: repeat(6, 1fr); 
    border-radius: 20px;
    border-width: 10px;
    border-color: #4610e7;
    display: grid;
    width: 100%;
    height: 91vh;
    user-select: none;
    background-color: #FFFFFF;
    padding: 0px;
    justify-content: center;
  }
}



@media (min-width: 1200px) {
  .grid-container {
    grid-template-columns: repeat(12, 1fr); 
    border-radius: 20px;
    border-width: 10px;
    border-color: #4610e7;
    display: grid;
    width: 100%;
    height: 91vh;
    user-select: none;
    background-color: #FFFFFF;
    padding: 0px;
    justify-content: center;
  }
}


.day-grid-container{
background-color: #ffffff;
grid-auto-columns: 1fr;
display: grid;
width: 100%;
height: 100%;
user-select: none;
}

day-grid-container:hover{
  background-color: #00ffff;
}

.day-item {
  border-radius: 0px;
  cursor: pointer;
  border: 0px solid rgba(210, 202, 202, 0.802);
  background-color: rgba(255, 255, 255, 0.758);
  padding-top: 5px;
  color:#434343;
  font-size: medium;
  font-weight:300;
  text-align: center;
  user-select: none;
}

.grid-padding{
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  user-select: none;
}

.top-grid-item-year {
  padding-top: 14px;
  font-size: 28px;
  font-weight:900;
  text-align: center;
  vertical-align:top;
  user-select: none;
}

</style>

