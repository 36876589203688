
<template>
  <div>
    <exchange-navbar
      :title="brandName"
      :items="menuItems"
    />
    <router-view />
  </div>
</template>

<script>
import ExchangeNavbar from "./components/ExchangeNavbar.vue";
export default {
  name: 'App',
  components: {
    ExchangeNavbar
  },
  
  data() {
    return {
      brandName: "Annucal - Plan ahead",
      menuItems: [
        
        
      ],
    }
  }
}
</script>

<style lang="scss">
  @import 'assets/styles/variables.scss';
  @import '~bulma/bulma.sass';
  @import 'assets/styles/main.scss';
  
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');

  $navbar-item-hover-color: red;

</style>