
import { createStore } from "vuex";
import exchange from "./modules/exchange";
import user from "./modules/user";
import toast from "./modules/toast";
import calender from "./modules/calender";
import navbar from "./modules/navbar";
import events from "./modules/events";
import settings from "./modules/settings";
import publicholidays from "./modules/publicholidays";
import schoolholidays from "./modules/schoolholidays";
import storedevents from "./modules/storedevents";
import content from "./modules/launchcontent"

export default createStore({
  modules: {
    exchange,
    user,
    toast,
    calender,
    navbar,
    events,
    settings,
    publicholidays,
    schoolholidays,
    storedevents,
    content
  }
})