import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faGear } from '@fortawesome/free-solid-svg-icons'
import { faPerson } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons'
import { faPersonWalkingDashedLineArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faCircleQuestion} from '@fortawesome/free-regular-svg-icons'
import { faCircleXmark} from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft} from '@fortawesome/free-solid-svg-icons'

import './assets/sass/style.scss'


import DatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

library.add(faStar)
library.add(faUserSecret)
library.add(faGear)
library.add(faPerson)
library.add(faUser)
library.add(faPersonWalkingDashedLineArrowRight)
library.add(faCalendarDays)
library.add(faCircleQuestion)
library.add(faCircleXmark)
library.add(faArrowLeft)


let app;



store.dispatch("user/onAuthChange", () => {
  
  if (!app) {
    app = createApp(App);
    
    app
  
    .use(router)
    .use(store)
  
    .use(Toast);

    app.component("font-awesome-icon", FontAwesomeIcon);
    app.component('DatePicker', DatePicker);
  
    app.mount('#app')
  }
});