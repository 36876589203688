const menuItems = [

    {text: "Home", link: "/"},
    {text: "Jahresplanung", link: "/"},
    

]

const brandName = "Annucal"

export default {

    state(){
        return{
            menuItems,
            brandName
        }
    }
}