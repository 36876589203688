<template>
  <div class="page-wrapper vertical-center">
    <div class="container has-text-centered">
      <div class="column is-4 is-offset-4">
        <div>Registrieren</div>
        <div class="box">
          <form>
            <div class="field">
              <div class="control">
                <input
                  v-model="form.email"
                  class="input is-large"
                  type="email"
                  placeholder="Email"
                  autocomplete="email">
                <!--<div
                  class="form-error">
                  <div class="help is-danger">Invalid Value</div>
                </div>-->
              </div>
            </div>
            <div class="field">
              <div class="control">
                <input
                  v-model="form.username"
                  class="input is-large"
                  type="text"
                  placeholder="Username"
                >
              </div>
            </div>
            <div class="field">
              <div class="control">
                <input
                  v-model="form.password"
                  class="input is-large"
                  type="password"
                  placeholder="Passwort"
                  autocomplete="current-password">
              </div>
            </div>
            <div class="field">
              <div class="control">
                <input
                  v-model="form.passwordConfirmation"
                  class="input is-large"
                  type="password"
                  placeholder="Passwort wiederholen"
                >
              </div>
            </div>
            <button
              @click="register"
              :disabled="isProcessing"
              type="button"
              class="button is-block is-info is-large is-fullwidth">
              Registrieren
            </button>
          </form>
          <p class="white-text">test</p>
          <a href="#" class="has-text-grey">Schon registriert?</a>&nbsp;
          <router-link
                to="/login"
               >
                Mit Deinem Konto anmelden
              </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useAuth from "../composition/useAuth";

export default {
  data() {
    return {
      form: {
        email: "",
        username: "",
        password: "",
        passwordConfirmation: ""
      }
    }
  },
  setup() {
    return useAuth();
  },
  watch: {
    isAuthenticated(isAuth) {
      if (isAuth) { this.$router.push("/"); }
    }
  },
  methods: {
    register() {
      this.$store.dispatch("user/register", this.form)
    }
  }
}
</script>
<style scoped>

.white-text{
  color:white
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 30%;
  -ms-transform: translateY(-30%);
  transform: translateY(-30%);
  width:100%;
}
  .hero.is-success {
    background: #F2F6FA;
  }
  .hero .nav, .hero.is-success .nav {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .box {
    margin-top: 1rem;
  }
  .avatar {
    margin-top: -70px;
    padding-bottom: 20px;
  }
  .avatar img {
    padding: 5px;
    background: #fff;
    border-radius: 50%;
    -webkit-box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);
    box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);
  }
  input {
    font-weight: 300;
  }
  p {
    font-weight: 700;
  }
  p.subtitle {
    padding-top: 1rem;
  }
</style>