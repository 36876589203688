var publicholidays = [
    
    {
      id: "ad7a123773888asT03", 
      title: "Neujahr",
      datefrom: "2023-01-01",
      states:["Alle Bundesländer", "Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "ad7a123773888xcd03", 
        title: "Heilige drei Könige",
        datefrom: "2023-01-06",
        states:["Baden Württemberg", "Bayern", "Sachsen-Anhalt"]
    },
    {
        id: "ad7are7773888xcd03", 
        title: "Internationaler Frauentag",
        datefrom: "2023-03-08",
        states:["Berlin", "Mecklenburg-Vorpommern"]
    },
    {
        id: "ad7ar45673888xcd03", 
        title: "Karfreitag",
        datefrom: "2023-04-07",
        states:["Alle Bundesländer", "Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "ad7ar456dft88xcd03", 
        title: "Ostersonntag",
        datefrom: "2023-04-09",
        states:["Alle Bundesländer", "Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "ad7ar45wxd888xcd03", 
        title: "Ostermontag",
        datefrom: "2023-04-10",
        states:["Alle Bundesländer", "Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "ad7ar45wxd888xcd03", 
        title: "Tag der Arbeit",
        datefrom: "2023-05-01",
        states:["Alle Bundesländer", "Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "ad7ar45wxd888xcd03", 
        title: "Christi Himmelfahrt",
        datefrom: "2023-05-18",
        states:["Alle Bundesländer", "Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "ad7ar45wxd888xcd03", 
        title: "Pfingstsonntag",
        datefrom: "2023-05-28",
        states:["Brandenburg",]
    },
    {
        id: "ad7ar45wxd888xcd03", 
        title: "Pfingstmontag",
        datefrom: "2023-05-29",
        states:["Alle Bundesländer", "Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "ad7ar45wxdderxcd03", 
        title: "Fronleichnam",
        datefrom: "2023-06-08",
        states:["Baden Württemberg", "Bayern", "Hessen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland"]
    },
    {
        id: "ad7ar45wx3828xcd03", 
        title: "Maria Himmelfahrt",
        datefrom: "2023-08-15",
        states:["Bayern","Saarland"]
    },
    {
        id: "ad7de35wxd888xcd03", 
        title: "Welt Kindertag",
        datefrom: "2023-09-20",
        states:["Thüringen",]
    },
    {
        id: "ad73455wxd888xcd03", 
        title: "Tag der deutschen Einheit",
        datefrom: "2023-10-03",
        states:["Alle Bundesländer", "Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "ad73455wxdav8xcd03", 
        title: "Reformationstag",
        datefrom: "2023-10-31",
        states:["Brandenburg","Bremen","Hamburg","Mecklenburg-Vorpommern","Niedersachsen","Sachsen","Sachsen-Anhalt","Schleswig-Holstein","Thüringen",]
    },
    {
        id: "ad73455w2axv8xcd03", 
        title: "Allerheiligen",
        datefrom: "2023-11-01",
        states:["Baden Württemberg","Bayern","Nordrhein-Westfalen","Rheinland-Pfalz","Saarland"]
    },
    {
        id: "ad73455wxdav8xcd03", 
        title: "Buß- und Bettag",
        datefrom: "2023-11-22",
        states:["Sachsen"]
    },
    {
        id: "xt7a123773888asT03", 
        title: "1. Weihnachtsfeiertag",
        datefrom: "2023-12-25",
        states:["Alle Bundesländer", "Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
      },
      {
        id: "vf7a123773888asT03", 
        title: "2. Weihnachtsfeiertag",
        datefrom: "2023-12-26",
        states:["Alle Bundesländer", "Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
      }
  ]
  
  export default {
  
      state(){
          return{
              items: [],
          }
      },
      actions:{
          getPublicHolidays({commit}){
              commit("setPublicHolidays",publicholidays);
          }
      },
      mutations:{
          setPublicHolidays(state, publicholidays){
              state.items = publicholidays;
          }
      }
  }
  
  