
  export default {
  
      state(){
          return{
            settingsPageIsVisible: false,
            showFeiertage: false,
            showSchulferien: false,
            showKalenderwochen: false,
            bundesLaender: '',
            FirstDayOfEventSelected: false,
            LastDayOfEventSelected: false,
            mouseDownEventStartDate: false,
            mouseDownEventEndDate: false,
            addDayOnEvent: false,
            ismousedown: false,
            selectedEventIDtoChangeDate: "",
            slectedDayId: "",
            SlotHeader: "",
            ShowDeleteButton: false,
            SlotMode: "",
            SlotColor: "",
            eventName: "",
            eventColor: "#eeeeee", 
            currentEventId: "",
            currentEventIdDB: "",
            BackColorWeekend: "#d9d9d9",
            BackColorNormalDay: "#ffffff",
            isdragging: false,
            selectedCursor: 'default',
            selectedYear: 2023,
            ctrlPressed: false,
            FixedWithMousUp: false,
            fetchedDataFromDb: false,
            userID: "tets",
            Authenticated: false,
            currentdatefrom: "",
            currentdateto: "",
            currentdatefromJson: "",
            currentdatetoJson: "",
            eventNeedsSaving: "no",
            popOverCalenderOpen: false,
            startPageOpen: false,
            introPageToShow: 3,
            currentRealDate:""
          }
      },
  }
  
  