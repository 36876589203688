<template>

<div class="grid">

  <div class="leftgridcolumn">
    <router-link
      to="/">
      <img src="@/assets/images/annucal_logo.png" 
      @click="showStartPage()"
      alt="Annucal" 
      class="logo"
      >
    </router-link>
  </div>

  <div>
    <div class="gridyear"  to="/">
      <PopoverYear placement="bottom" />
    </div>
  </div>


  <div class="item2">
    <div class="menuitem settings top">
      <div
          to="/calender"
          class="nav-web">
          <PopoverSettings placement="bottom" />
      </div>
    </div>

    <div class="menuitem settings top">
      <div
          to="/calender"
          class="nav-web">
          <PopoverFaq placement="bottom" />
      </div>
    </div>

    <!--<div class="menuitem">
      <router-link
          @click="() => this.$store.state.settings.settingsPageIsVisible = false"
          to="/calender"
          class="navbar-item nav-web">
          <font-awesome-icon icon="fa-solid fa-calendar-days" />
      </router-link>
    </div>-->

    <div class="menuitem top">
      <div
          to="/calender"
          class="nav-web">
          <PopoverLogin placement="bottom" />
      </div>
    </div>

    <!--<div class="menuitem">
          
           <template v-if="isAuthenticated">
              <div
                @click="() => $store.dispatch('user/logout')"
                class="navbar-item clickable">
                <font-awesome-icon icon="fa-solid fa-person-walking-dashed-line-arrow-right" />
              </div>
         
            </template>
            <template v-else>
              <router-link
                to="/login"
                class="navbar-item">
                <font-awesome-icon icon="fa-solid fa-user" />
              </router-link>
            </template>
    </div>-->

  </div>
  
</div>

</template>

<style  scoped>

.leftgridcolumn{
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items:center;
  justify-content:left;
}

.settings{
  padding-bottom: 0px;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.gridyear{
  background-color: rgb(255, 255, 255);
  height: 100%;
  font-size: xx-large;
  font-weight: 300;
  color:rgb(88, 88, 88);
  display: flex;
  align-items:center;
  justify-content:center;
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none; 

  
}

.grid {
  display: grid;
  height: 60px;
  width:100%;
  grid-template-columns: 30% 40% 30%;
}

.menuitem{
  display: table-cell;
  width: 50px;
  padding: 10px;
  vertical-align: middle;
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none; 
  
}

@media (min-width: 100px){
  .item1 {
    background-color: rgb(154, 154, 154);
    display: table-cell;
    width: 95px;
    padding-left: 30px;
    padding-top: 16px;
    vertical-align: middle;
  } 

  .logo{
    width:145px;
    padding-left:45px;
    padding-top:3px;
  }

  .topyear{
    margin-top: 5px;
    margin-right: 30px;
  }

  .item2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: rgb(255, 255, 255);
  justify-items: right;
  padding-top: 14px;
  padding-right: 10px;
  display: flex;
  align-items:center;
  justify-content:right;
}

}

@media (min-width: 700px){
  .item1 {
    background-color: rgb(154, 154, 154);
    display: table-cell;
    width: 260px;
    padding-left: 40px;
    padding-top: 20px;
    vertical-align: middle;
  } 
  .logo{
    width:160px;
    padding-left:45px;

  }
  .topyear{
    margin-top: 6px;
    margin-right: 30px;
    font-weight: 900;
    font-size: larger;
  }

  .item2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: rgb(255, 255, 255);
  justify-items: right;
  
  padding-right: 15px;
  display: flex;
  align-items:center;
  justify-content:right;
}



}

@media (min-width: 1200px){
  .item1 {
    background-color: rgb(154, 154, 154);
    display: table-cell;
    width: 33%;
    padding-left: 40px;
    padding-top: 18px;
    vertical-align: middle;
    margin-top:0px;
  }

  .logo{
    width:170px;
    padding-left:45px;
     display: flex;
  align-items:center;
  justify-content:left;
   
  }

  .topyear{
    margin-top: 25px;
    margin-right: 28px;
    font-weight: 900;
    font-size: xx-large;
    color: rgb(93, 93, 93);
     display: flex;
  align-items:center;
  justify-content:center;
  }


  .item2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: rgb(255, 255, 255);
  justify-items: right;
  margin-top: -5px;
  padding-right: 8%;
  display: flex;
  align-items:center;
  justify-content:right;
}
}



</style>

<script>

import PopoverSettings from '../components/PopoverSettings.vue'
import PopoverLogin from '../components/PopoverLogin.vue'
import PopoverYear from '../components/PopoverYear.vue'
import PopoverFaq from '../components/PopoverFaq.vue'
import useAuth from '../composition/useAuth'
import { useStore } from "vuex";
export default {
  components: { 
    PopoverSettings,
    PopoverLogin,
    PopoverYear,
    PopoverFaq },

  props: {
    title: {
      type: String,
      default: "Annucal",
    },

    items: {
      type: Array,
      required: true,
    }
  },

  setup() {
    const store = useStore();
    const showStartPage = () => {
      store.state.settings.introPageToShow = 1
      store.state.settings.startPageOpen = true
    }

    return {
      showStartPage,
      useAuth,
    }
  }
}
</script>
