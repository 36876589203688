
var content = [
    {
        headline1: "Einfache Jahresplanung am",
        headline2: "Start",
        subheadline1: "Annucal ist deine Jahresplanungsplattform, mit der du immer und überall das gesamte Jahr im Blick hast - und noch vieles mehr.",
        subheadline2: "Einfacher und über-sichtlicher kannst du dein Jahr nicht planen",
        image1: 1,
        image2: 1,
        mainText: "Teste ohne Anmeldung alle Funktionen oder erstelle gleich dein kostenloses Konto, um deine persönliche Jahresplanung speichernund später bearbeiten zu können.",
        buttonText: "jetzt loslegen",
        showimage1: true
    },
    {
        headline1: "Alle Feiertage und Schulferien im",
        headline2: "Überblick",
        subheadline1: "Annucal zeigt dir eine Übersicht aller unter- schiedlichen Feiertage und Schulferien deutscher Bundesländer.",
        subheadline2: "Auf welchen Wochentag fällt Weihnachten dieses Jahr?",
        image1: 2,
        image2: 2,
        mainText: "Lass dir ohne Anmeldung alle Feiertage und Schulferien des ganzen Jahres übersichtlich anzeigen oder erstelle gleich ein kostenloses Konto, um deine persönlichen Eintragungen speichern und später bearbeiten zu können.",
        buttonText: "jetzt loslegen",
        showimage1: true
    },
    {
        headline1: "Leichte und übersichtliche",
        headline2: "Planung",
        subheadline1: "Markiere und betitele Zeiträume per Klick. Verschiebe, verlängere oder verkürze Zeiträume per Drag & Drop.",
        subheadline2: "Planung per Klick - Änderung per Drag & Drop",
        image1: 3,
        image2: 3,
        mainText: "Teste ohne Anmeldung alle Funktionen, Übersichten und Möglichkeiten oder erstelle gleich dein kostenloses Konto, um deine persönlichen Eintragungen speichern und später bearbeiten zu können.",
        buttonText: "jetzt loslegen",
        showimage1: true
    },
    {
        headline1: "Plane Projekte und Events",
        headline2:  "",
        subheadline1:  "Du bist Projektleiter oder Freelancer? Bei der Planung und Durchführung von Projekten und Events können Wochenenden, Feiertage und Schulferien eine wichtige Rolle spielen. Annucal berücksichtigt sie alle.",
        subheadline2:  "Optimale Berücksichtigung von Wochenenden, Feiertagen und Schulferien",
        image1: 4,
        image2: 4,
        mainText:  "Teste ohne Anmeldung alle Funktionen und Übersichten oder erstelle gleich dein kostenloses Konto, um deine persönliche Projekt- oder Eventplanung speichern und später bearbeiten zu können.",
        buttonText: "jetzt loslegen",
        showimage1: true
    },
    {
        headline1: "Übersichtliche Reiseplanung für das gesamte Jahr",
        headline2:  "",
        subheadline1:  "Du bist Digital Nomad, Influencer, Frequent Traveller oder Leistungssportler? Endlich kannst du deine Reisen, Aufenthalte und Events übersichtlich planen und auf das Jahr verteilen.",
        subheadline2:  "Plane übersichtlich deine Reisen und Aufenthalte",
        image1: 5,
        image2: 5,
        mainText:  "Plane ohne Anmeldung deine Reisen, Aufenthalte und Events oder erstelle gleich dein kostenloses Konto, um deine persönlichen Eintragungen speichern und später bearbeiten zu können.",
        buttonText: "jetzt loslegen",
        showimage1: true
    },
    {
        headline1: "Überschaubare Buchungsplanung für das komplette Jahr",
        headline2:  "",
        subheadline1:  "Du hast eine Ferienwohnung? Mit Annucal siehst du immer alle deine Buchungen auf einen Blick und kannst jederzeit und von überall Änderungen vornehmen.",
        subheadline2:  "Plane effizient die eigene Nutzung und die Vermietung deiner Ferienwohnung",
        image1: 6,
        image2: 6,
        mainText:  "Trage ohne Anmeldung deine Buchungen und Blocks deiner Ferienwohnung ein und oder erstelle gleich dein kostenloses Konto, um deine Eintragungen speichern und später bearbeiten zu können.",
        buttonText: "jetzt loslegen",
        showimage1: true
    }
]

export default {
    
    state(){
        return{
            items: [],
        }
    },
    actions:{
      getContent({commit}){
        commit("setContent",content);   
    }
  },

  mutations:{
    setContent(state, content){
          state.items = content;
      }
  }
}

