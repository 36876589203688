<template>
    

<div class="card" style="
padding-left:15px; 
padding-right:15px;
">
    
<!-- MOBILE VERSION -->
<div v-if="screenWidth < 800">
<!----- TEXT 1 ----->
    <div v-if="content.headline2 != 'Planung'" style="
            color:#0b3558;
            font-size: xx-large;
            font-weight: 500;
            text-align: left;
            font-family: 'Roboto-Black';
            padding-right:35px;
            padding-left:25px;
            padding-top:20px;
          ">
            {{content.headline1}}&nbsp;<mytest style="
            color: #0099FF;">
            {{content.headline2}}</mytest>
    </div>

    <div v-if="content.headline2 == 'Planung'" style="
            color:#0b3558;
            font-size: xx-large;
            font-weight: 500;
            text-align: left;
            font-family: 'Roboto-Black';
            padding-right:35px;
            padding-left:25px;
            padding-top:20px;
          ">
            {{content.headline1}}&nbsp;
            {{content.headline2}}
    </div>


        <!----- TEXT 2 ----->
        <div style="
           color:#0b3558;
      font-size: medium;
      font-weight: 600;
      text-align: left;
      font-family: 'Roboto-Light';
      padding-right:15px;
      padding-top:10px;
      padding-left:25px;  
        ">
            {{content.subheadline1}}
        </div>

        

    <div v-if="content.showimage1" class="card-image" style="
    padding-left:25px;
    padding-right:25px;
    padding-top:30px;
    padding-bottom:20px;
    ">
      <figure >
        <img :src=setImagePath1() /> 
      </figure>
    </div>

<div class="card-content">
    <div  style="padding-left:10px" class="headline-two"> {{ content.subheadline2}} </div>
    <div  style="padding-left:0px">
        <img 
        :src=setImagePath2() 
        style="
            padding-top: 0px;
            padding-left: 0px;
            padding-right: 20px;
            padding-bottom: 15px;
            align-items:center;
            justify-content:center;
        "/> 
    </div>
    <div class="text-two"> {{ content.mainText }} </div>        
</div>

</div>

    
  <!--  DESKTOP VERSION -->
<div v-if="screenWidth > 800">
  <!----- TEXT 1 ----->
 <div style="
                color:#0b3558;
                font-size: xx-large;
                font-weight: 500;
                text-align: left;
                font-family: 'Roboto-Black';
                padding-right:5px;
                padding-left:25px;
                padding-top:20px;
          ">
            {{content.headline1}}<span style="color: #0099FF">&nbsp;{{content.headline2}}</span>
        </div>

        <!----- TEXT 2 ----->
        <div style="
           color:#0b3558;
      font-size: medium;
      font-weight: 600;
      text-align: left;
      font-family: 'Roboto-Light';
      padding-right:15px;
      padding-top:10px;
      padding-left:25px;  
        ">
            {{content.subheadline1}}
        </div>

        

    <div v-if="content.showimage1" class="card-image" style="
    padding-left:25px;
    padding-right:25px;
    padding-top:30px;
    padding-bottom:20px;
    ">
      <figure >
        <img :src=setImagePath1() /> 
      </figure>
    </div>
    
    <div class="card-content"   >
     <div class="grid-container2" >
        <div class="grid-item2" style="padding-left:0px">
            <img 
            :src=setImagePath2() 
            style="
                padding-top: 0px;
                text-align: left;
                padding-right: 50px;
            "/> 
        </div>
        <div class="grid-item2" style="padding-left:15px">
            <p class="headline-two"> {{ content.subheadline2}} </p>
            <p class="text-two"> {{ content.mainText }} </p>
        </div>
    </div>
  </div>
</div>
  
   <!--
      <div class="center">
        <button v-if="isAuthenticated  == false"
            class="mybutton1" @click="this.$store.state.settings.introPageToShow = 2"
            style="
            margin-top:75px;
            margin-bottom: 30px;
            ">
            {{ content.buttonText}}
        </button>
        <button v-if="isAuthenticated  == true"
            class="mybutton1" @click="close()"
            style="
            margin-top:75px;
            margin-bottom: 30px;
            ">
            {{ content.buttonText}}
        </button>
      </div>
      -->
      
     
    
  </div>
</template>

<style scoped>
@media (min-width: 100px){

  #mytest{
    color: #0099FF 
  }
  .headline-two{
    color:#0b3558;
                font-size: x-large;
                font-weight: 500;
                text-align: left;
                font-family: 'Roboto-Black';
                padding-right:0px;
                padding-left:0px;
                padding-top:0px;  
                padding-bottom:30px;         
  }

  .text-two{
    color:#0b3558;
                font-size: medium;
                font-weight: 600;
                text-align: left;
                font-family: 'Roboto-Light';
                padding-right:0px;
                padding-top:15px;
                padding-left:0px;
  }

  .video-text{
    
  }

}

@media (min-width: 1200px){
  .headline-two{
      color:#0b3558;
      font-size: x-large;
      font-weight: 500;
      text-align: left;
      font-family: 'Roboto-Black';
      padding-right:0px;
      padding-top:0px;
      padding-left:15px;           
  }

  .text-two{
    color:#0b3558;
                font-size: small;
                font-weight: 600;
                text-align: left;
                font-family: 'Roboto-Light';
                padding-right:0px;
                padding-top:0px;
                padding-left:15px;
  }
  
}

.grid-container2 {  
    display: grid;
    grid-template-columns: 50% 50% ;
    background-color: #ffffff;
    padding-left: 0px;
    padding-right: 10px;
}
.grid-item2 {
  background-color: rgba(255, 255, 255, 0.8);
  color: #0b3558;
  
  text-align: left;
}
.center {
  display: flex;
justify-content: center;
}
.mybutton1 {
  background-color: #0099FF; 
  border: none;
  color: white;
  padding-left: 22px;
  padding-right: 22px;
  padding-bottom: 8px;
  padding-top: 7px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: 'Roboto-Medium';
  font-size: x-large;
  font-weight: 500;
  cursor: pointer;
  border-radius: 23px;
}
.mybutton2 {
  background-color: #0099FF; 
  border: none;
  color: white;
  padding-left: 13px;
  padding-right: 12px;
  padding-bottom: 7px;
  padding-top: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-size: small;
  font-weight: 500;
  cursor: pointer;
  border-radius: 23px;
}
</style>

<script>
import {ref,computed} from 'vue'
import { useStore } from "vuex";

export default {
  data() {
    return {
      screenWidth: 0,
    };
  },
  mounted() {
    this.updateScreenWidth();
    this.onScreenResize();
  },
  methods: {
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },

      setup(props){
        const store = useStore();
        const isAuthenticated = computed(() => store.getters["user/isAuthenticated"]);
        

        function close(){
          console.log("Close now")
          store.state.settings.introPageToShow = 2
          store.state.settings.startPageOpen = false
        }

        function setImagePath1() {
            const imgSrc = ref()
            switch(props.content.image1) {
                case 1:
                    imgSrc.value = require("@/assets/images/StartSeite_2.png")
                    return imgSrc.value
                case 2:
                    imgSrc.value = require("@/assets/images/StartSeite_3.png")
                    return imgSrc.value
                case 3:
                    imgSrc.value = require("@/assets/images/StartSeite_4.png")
                    return imgSrc.value
                case 4:
                    imgSrc.value = require("@/assets/images/StartSeite_6.png")
                    return imgSrc.value
                case 5:
                    imgSrc.value = require("@/assets/images/StartSeite_7.png")
                    return imgSrc.value
                case 6:
                    imgSrc.value = require("@/assets/images/StartSeite_8.png")
                    return imgSrc.value
                case 0:
                imgSrc.value = require("@/assets/images/startpicture01.png")
                return imgSrc.value
            }         
        }

        function setImagePath2() {
            const imgSrc = ref()
            switch(props.content.image2) {
                case 1:
                    imgSrc.value = require("@/assets/images/StartSeite_2_1.png")
                    return imgSrc.value
                case 2:
                    imgSrc.value = require("@/assets/images/StartSeite_3_1.png")
                    return imgSrc.value
                case 3:
                    imgSrc.value = require("@/assets/images/StartSeite_4_1.png")
                    return imgSrc.value
                case 4:
                    imgSrc.value = require("@/assets/images/StartSeite_6_1.png")
                    return imgSrc.value
                case 5:
                    imgSrc.value = require("@/assets/images/StartSeite_7_1.png")
                    return imgSrc.value
                case 6:
                    imgSrc.value = require("@/assets/images/StartSeite_8_1.png")
                    return imgSrc.value
            }         
        }

        return{
          isAuthenticated,
          close,
          setImagePath1,
          setImagePath2
        }
      },

      props: {
        content: {
          type: Object,
          required: true
        },
      }

    }
  </script>