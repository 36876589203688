<template>
    <div >
    <!----- TEXT 2 ----->
    <div style="
           color:#0b3558;
      font-size: medium;
      font-weight: 600;
      text-align: left;
      font-family: 'Roboto-Light';
      padding-right:15px;
      padding-top:25px;
      padding-bottom:35px;  
        ">
            <p>Annucal</p> 
            <p>Inh. Jan-Willem Wulff</p>
            <p>Lerchenstr. 28</p>
            <p>22767 Hamburg</p>
            <p>Deutschland</p>
            <br />
            <p>Tel.: 040-50719309</p>
            <p>E-Mail: info@annucal.com</p>
            
            
          </div>
</div>
</template>

<script>
export default {
    name: 'ContactForm',
    data: function () {
        return {
            contactFormData: {
                name: '',
                email: '',
                message: '',
            },
            success: false,
            error: false,
        }
    },
    methods: {
        sendMail: function () {
            // in a real app, it would be better if the URL is extracted as a env variable
            const url = ' http://localhost:5001/vue-contact-form-3c668/us-central1/sendEmailCF';
            const {name, email, message} = this.contactFormData;
            const payload = {name, email, message};
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            })
                .then(() => {
                    this.success = true;
                    this.resetForm();
                })
                .catch(() => {
                    this.error = true;
                })
        },
        resetForm: function () {
            this.contactFormData = {
                name: '',
                email: '',
                message: '',
            };
        },
    },
}
</script>

<style scoped>
.contact-form {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 360px;
}
.form-group {
    padding: 10px;
}
.form-control {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
input[type=text].form-control {
    margin: 8px 0;
    display: inline-block;
}
textarea.form-control {
    resize: none;
}
.btn {
    cursor: pointer;
    padding: 8px 10px;
    outline: none;
    border: none;
    background: #3be249;
    font-size: 16px;
    width: 100%;
    border-radius: 4px;
    text-align: center;
}
.alert {
    padding:0 10px;
}
.alert-success {
    color:#3be249;
}
.alert-error {
    color: #ff2121;
}
</style>
view raw