
<template>
  <div class="tooltip">
    
    <div 
        aria-describedby="tooltip" 
        @mouseenter="handleShow($event)" 
        @mouseleave="handleHide($event)" 
        @focus="handleShow($event)" 
        @blur="handleHide($event)">
        <table>
            <td class="weekday-number">&nbsp;{{item.name}}&nbsp;</td>
            <td class="weekday-name">{{item.weekday}}</td>
        </table>
    </div>
    
    <div class="tooltipText" role="tooltip">

     <div>
     {{item.dateFormated_De}}
     </div>
      <div>
     {{item.eventText}}
     </div>
     <div>
     {{showTooltip2(item.eventText, item.PublicHolidayName)}}
     </div>
      <div class="tooltipArrow" data-popper-arrow></div>
    </div>
  </div>
</template> 

<script>
import { defineComponent, ref, computed } from 'vue'
import { createPopper } from '@popperjs/core'

export default defineComponent({
  name: 'Tooltip',
  props: {
    placement: String,
    item:Object,
  },
  methods:{
    showTooltip2(value1, value2){
      if(value1 == value2){
        return "";
      }
      else{
        return value2;
      }
    }
  },

  setup(props) {
    const button = ref(null)
    const tooltip = ref(null)

    const popperInstance = computed(() => {
      return createPopper(button.value, tooltip.value, {
        placement: props.placement,//'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
        ],
        strategy: 'absolute'
      })
    })

    const insertElement = (btn, tip) => {
      button.value = btn
      tooltip.value = tip
    }

    const handleShow = (e) => {
      if(button.value === null && tooltip.value === null) {
        insertElement(e.target, e.target.parentElement.querySelector('.tooltipText'))
      }
      tooltip.value.setAttribute('data-show', '')
      popperInstance.value.update()
    }

    const handleHide = (e) => {
      if(button.value === null && tooltip.value === null) {
        insertElement(e.target, e.target.parentElement.querySelector('.tooltipText'))
      }
      tooltip.value.removeAttribute('data-show')
    }

    return {
      handleShow,
      handleHide
    }
  }
})
</script>

<style scoped>

.item-small {
    user-select: none;
    display: flex;
    align-items:center;
    grid-column: 1 / span 1;
    padding-left: 0px;
    padding-right: 0px;
    font-size:x-small;
    font-weight: 500;
    height:100%;
    border-top-width: 1px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-style: solid;
    border-top-color: rgb(197, 194, 194);
}

.item-large {
    display: flex;
    align-items:center;
    justify-content:center;
    padding-left: 0px;
    
    border-radius: 0px;
    grid-column: 2 / span 2;
    text-align:center;
    font-size: x-small;
    color:#0b3558;
    text-align: center;
    font-weight: 400;
    height:100%;
    width:90%;
    right:2%;
    left:2%;
    border-top-width: 1px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-style: solid;
    border-top-color: rgb(197, 194, 194);
}
.weekday-number{
    font-weight: 800;
    font-size: 11px;
    color:#0b3558;
} 

.weekday-name{
    font-weight: 300;
    font-size: 11px;
}
.day-grid-container{
  background-color: #ffffff;
  grid-auto-columns: 1fr;
  display: grid;
  width: 100%;
  height: 100%;
  user-select: none;

}
.button {
  appearance: none;
  border: 1px solid rgba(0, 0, 0, 0.25);
  background-color: seagreen;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 12pt;
  color: white;
}

.tooltip{
  
}

.tooltipText {
  background-color: rgb(255, 255, 255);
  color: #0b3558;
  padding: 7px 10px;
  border-radius: 4px;
  font-size: 13px;
  display: none;
  border: 1px solid rgba(66, 66, 66, 0.5);
}

.tooltipText[data-show] {
  display: block;
}

.tooltipArrow,
.tooltipArrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.tooltipArrow {
  visibility: hidden;
}

.tooltipArrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
  border-bottom: 1px solid rgba(66, 66, 66, 0.5);
  border-right: 1px solid rgba(66, 66, 66, 0.5);
  
}

.tooltipText[data-popper-placement^='top'] > .tooltipArrow {
  bottom: -5px;

}

.tooltipText[data-popper-placement^='bottom'] > .tooltipArrow {
  top: -5px;

}

.tooltipText[data-popper-placement^='left'] > .tooltipArrow {
  right: -5px;

}

.tooltipText[data-popper-placement^='right'] > .tooltipArrow {
  left: -5px;

}
</style>
