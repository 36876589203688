
<template>
    <div>
      <div 
        style="
          cursor: pointer;
          padding-top: 0px;
          width: 85px;
          color: rgb(29, 29, 29);"
          icon="fa-solid fa-gear"  
          @mouseenter="handleShow($event)" 
          @click="toggle === false ? handleClick($event) : handleHide($event)">
    
          {{ this.$store.state.settings.selectedYear }} 

      </div>
  
      <!------------------------ YEAR SELECTOR START   ------------------------>
      <div class="popoverPanel">
        <div class="popoverArrow" data-popper-arrow></div>
        <div style="
          padding-top: 25px;
          display: flex;
          justify-content: center">
          <form>
            <div class="control" style="text-align: left;padding-top: 0px;padding-left: 0px;">
                <div><label class="radio year-name"><input type="radio" name="answer" @change="yearClicked(2023)" checked="checked">&nbsp; 2023</label></div>
                <div><label class="radio year-name"><input type="radio" name="answer" @change="yearClicked(2024)">&nbsp; 2024</label></div>
                <div><label class="radio year-name"><input type="radio" name="answer" @change="yearClicked(2025)">&nbsp; 2025</label></div>
                <div>&nbsp;</div>
            </div>
          </form>
          </div>
          <div class="modal-card-foot go-right">
          <button class="button "
          @click="handleHide($event)"
          >Schließen</button>
        </div>
      </div>
      <!------------------------ YEAR SELECTOR END   ------------------------>


    </div>
  </template>
  
  <script>
  
  import { defineComponent, ref, computed } from 'vue'
  import { createPopper } from '@popperjs/core'
  import calenderCore from "../composition/calenderCore"
  
  
  export default defineComponent({
    
    name: 'PopoverSettings',
    props: {
      placement: String
    },
  
    setup(props) {
      const button = ref(null);
      const popover = ref(null);
      const toggle = ref(false);
      const calenderCore1 = calenderCore();
      
      const popperInstance = computed(() => {
        return createPopper(button.value, popover.value, {
          placement: props.placement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
          ],
          strategy: 'absolute'
        })
      })
  
      const insertElement = (btn, tip) => {
        button.value = btn
        popover.value = tip
      }
  
      const handleShow = (e) => {
        if(button.value === null && popover.value === null) {
          insertElement(e.target, e.target.parentElement.querySelector('.popoverPanel'))
        }
      }
  
      const handleClick = (e) => {
        if(button.value === null && popover.value === null) {
          insertElement(e.target, e.target.parentElement.querySelector('.popoverPanel'))
        }
        popover.value.setAttribute('data-show', '')
        popperInstance.value.update()
        toggle.value = true
      }
  
      const handleHide = (e) => {
        if(button.value === null && popover.value === null) {
          insertElement(e.target, e.target.parentElement.querySelector('.popoverPanel'))
        }
        popover.value.removeAttribute('data-show');
        toggle.value = false
      }
  
      function yearClicked(selectedYear){
        this.$store.state.settings.selectedYear = selectedYear;
        this.$store.commit("setYear", selectedYear)
        this.$store.dispatch("getCalender")
        calenderCore1.updateCalender();
      }
  
      function close(){
        this.showModal = false; 
      }
  
      return {
        toggle,
        handleShow,
        handleClick,
        handleHide,
        yearClicked,
        close
      }
    }
  })
  </script>
  
  <style scoped>
  
  .go-right{
    justify-content: flex-end;
    background-color: white;
  }
  
  .year-name{
    font-size: medium;
    font-weight: 400;
    color: #0b3558;
  }
  .toggle {
    margin-top: 4px;
   
    position: relative;
  }
  .toggle input[type="checkbox"] {
    opacity: 0;
    position:absolute;
  }
  .toggle label {
    background-color: rgba(0, 0, 0, 0.3);
    border: 0px solid #BBBBBB;
    border-radius: 20px 20px 20px 20px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1) inset;
    height: 15px;
    position: relative;
    width: 30px;
    display: block;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  .toggle input:checked ~ label {
    background-color: #19A8E4;
    background-image: linear-gradient(to bottom, #0391f5 0px, #0391f5 100%);
    border: 0px solid #0391f5;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.25) inset;
  }
  .toggle .toggle-handle {
      background-color: #FFFFFF;
      background-image: linear-gradient(to bottom, #FFFFFF 0px, #F2F2F2 100%);
      border: 2px solid rgba(0, 0, 0, 0.3);
      border-radius: 100px 100px 100px 100px;
      height: 15px;
      position: absolute;
      -webkit-transition: -webkit-transform 0.1s ease-in-out 0s, border 0.1s ease-in-out 0s;
      transition: transform 0.1s ease-in-out 0s, border 0.1s ease-in-out 0s;
      width: 15px;
      z-index: 2;
      pointer-events: none;
  }
  .toggle input:checked + .toggle-handle {
      border-color: #0391f5;
      -webkit-transform: translate3d(15px, 0px, 0px);
      transform: translate3d(15px, 0px, 0px);
  }
  .toggle label:after {
      color: #e81616;
      
      font-weight: bold;
      line-height: 28px;
      position: absolute;
      right: 13px;
      text-shadow: 0 1px #FFFFFF;
      text-transform: uppercase;
  }
  .toggle input:checked ~ label:after {
      color: #FFFFFF;
      
      left: 15px;
      right: auto;
      text-shadow: 0 -1px rgba(0, 0, 0, 0.25);
  }
  
  
  
  
  
  
  
  
  
  
  
  
  .popoverPanel {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.4);
    border-radius: .1rem;
    font-size: .875rem;
    display: none;
  }
  
  .popoverPanel[data-show] {
    display: block;
  }
  
  .popoverHeader {
    padding: .5rem 1rem;
    margin: 0;
    font-size: 1rem;
    background-color: #f0f0f0;
    border-bottom: 1px solid rgba(0,0,0,.2);
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
  }
  
  .popoverBody {
    padding: 1rem 1rem;
    color: #212529;
  }
  
  .popoverArrow,
  .popoverArrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }
  
  .popoverArrow {
    visibility: hidden;
  }
  
  .popoverArrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
  
  .popoverPanel[data-popper-placement^='top'] > .popoverArrow {
    bottom: -4px;
  }
  
  .popoverPanel[data-popper-placement^='top'] > .popoverArrow::before {
    border-bottom: 1px solid rgba(0,0,0,.5);
    border-right: 1px solid rgba(0,0,0,.5);
  }
  
  .popoverPanel[data-popper-placement^='bottom'] > .popoverArrow {
    top: -4px;
  }
  
  .popoverPanel[data-popper-placement^='bottom'] > .popoverArrow::before {
    border-top: 1px solid rgba(0,0,0,.5);
    border-left: 1px solid rgba(0,0,0,.5);
  }
  
  .popoverPanel[data-popper-placement^='left'] > .popoverArrow {
    right: -4px;
  }
  
  .popoverPanel[data-popper-placement^='left'] > .popoverArrow::before {
    border-top: 1px solid rgba(0,0,0,.5);
    border-right: 1px solid rgba(0,0,0,.5);
  }
  
  .popoverPanel[data-popper-placement^='right'] > .popoverArrow {
    left: -4px;
  }
  
  .popoverPanel[data-popper-placement^='right'] > .popoverArrow::before {
    border-bottom: 1px solid rgba(0,0,0,.5);
    border-left: 1px solid rgba(0,0,0,.5);
  }
  </style>
  