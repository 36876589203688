<template>
    <div class="page-wrapper vertical-center">
        <div >
            <div class="cointainer is-fluid ">
      <div class="column is-4 is-offset-4">
      <h3 class="title has-text-grey">PASSWORT VERGESSEN</h3>
      <article class="md:w-1/3 p-5 border rounded bg-gray-100 mx-auto">
        <transition name="fade">
          <p
            class="bg-red-100 p-5 my-5 border border-red-200 rounded text-red-500"
            v-if="error"
          >
            {{ error }}
          </p>
        </transition>
        <transition name="fade">
        <p
            class="bg-red-100 p-5 my-5 border border-red-200 rounded text-red-500"
            v-if="message"
          >
            {{ message }}
          </p></transition>
        <form @submit.prevent>
          <div class="mb-4">
    
            <div class="field">
            <input
              type="email"
              placeholder="Email"
              v-model="email"
              id="email"
              class="input is-large"
              required
            />
        </div>
          </div>
          <div class="mb-4">
            <button
              type="submit"
              @click="sendEmail"
              class="button is-block is-info is-large is-fullwidth"
            >
              <transition name="fade" mode="out-in">
                <span v-if="!emailSending">Passwort zurück setzen</span>
                <span v-else>Email wird gesendet...</span>
              </transition>
            </button>
          </div>
          
        </form>


      </article>
    </div>
    </div>
    </div>
</div>
  </template>
  
  <script>
  
  import {
    getAuth,
  sendPasswordResetEmail
} from "firebase/auth";

  export default {
    data() {
      return {
        email: "",
        error: null,
        emailSending: false,
        message: null
      };
    },
    methods: {
      sendEmail() {
        if (!this.email) {
          this.error = "Please type in a valid email address.";
          return;
        }
        this.error = null;
        this.message = null;
        this.emailSending = true;
        
          sendPasswordResetEmail(getAuth(),this.email)
          .then(() => {
            this.emailSending = false;
            this.message = "Eine Email zum zurück setzen Deines Passworts wurde an die angegebe Emailadresse geschickt";
          })
          .catch(error => {
            this.emailSending = false;
            if(error.message.includes ("invalid-email")){
                this.error = "Die Emailadresse scheint nicht korrekt zu sein"
            }
            else{
                this.error = "Ein Fehler ist aufgetreten. Bitte versuche es noch einmal" //error.message;
            }
            
          });
      },
    },
  };
  </script>

<style scoped>
.white-text{
  color:white
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 30%;
  -ms-transform: translateY(-30%);
  transform: translateY(-30%);
  width:100%;
}
  .hero.is-success {
    background: #F2F6FA;
  }
  .hero .nav, .hero.is-success .nav {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .box {
    margin-top: 1rem;
  }
  .avatar {
    margin-top: -70px;
    padding-bottom: 20px;
  }
  .avatar img {
    padding: 5px;
    background: #fff;
    border-radius: 50%;
    -webkit-box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);
    box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);
  }
  input {
    font-weight: 300;
  }
  p {
    font-weight: 700;
  }
  p.subtitle {
    padding-top: 1rem;
  }
</style>