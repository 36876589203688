import days2023 from "../../assets/Calendar2023.json"
import days2024 from "../../assets/Calendar2024.json"
import days2025 from "../../assets/Calendar2025.json"

var days = []


export default {

    state(){
        return{
            items: [],
            selectedYear: 2023,
        }
    },

    methods:{
        changeyear(){
            console.log("Change year now")
        }
    },

    actions:{
        getCalender({commit}){
            commit("setCalender", days);
        },

       
    },
    
    mutations:{

        setYear(state,year){
            state.selectedYear = year
        },

        setCalender(state, days){
            
            
            let position = 1;
            let offset = 0;
            let index = 0;
            state.items= []
            
            if( state.selectedYear == 2023){
                days = days2023
            }
            else if( state.selectedYear == 2024){
                days = days2024
            }
            else if( state.selectedYear == 2025){
                days = days2025
            }

            /* 4 Reihen
            if(window.innerWidth < 700 ){
                state.items = []
                for (let i = 0; i < days.length; i++) { 
                    state.items.push(days[index + offset]);
                    position++;
                    if(position == 5)
                    {
                        position = 1
                        offset += 8;
                    }
                    if(i  == 123){
                        index = 3
                        position = 1
                        offset = 0
                    }
                    if(i  == 247){
                        index = 7
                        position = 1
                        offset = 0
                    }
                    index++
                }
            }
            */
            
            if(window.innerWidth < 700 ){ // 3 Reihen
                
                state.items = []
                for (let i = 0; i < 372; i++) { 
                    
                    state.items.push(days[index + offset]);
                    position++;
                    if(position == 4)
                    {
                        position = 1
                        offset += 9;
                    }
                    if(i  == 92){
                        index = 2
                        position = 1
                        offset = 0
                    }
                    if(i  == 185){
                        index = 5
                        position = 1
                        offset = 0
                    }
                    if(i  == 278){
                        index = 8
                        position = 1
                        offset = 0
                    }
                  
                    index++
                }
            }

            else if(window.innerWidth > 699 && window.innerWidth < 1200 ){
                state.items = []
                for (let i = 0; i < 372; i++) { 
                    state.items.push(days[index + offset]);
                    position++;
                    if(position == 7)
                    {
                        position = 1
                        offset += 6;
                    }
                    if(i  == 185){
                        index = 5
                        position = 1
                        offset = 0
                    }
                 
                    index++
                }
            }
            else{
                for (let i = 0; i < 372; i++){
                    console.log(days[index + offset].id)
                }
                state.items = days;
            }
        },
    }
}



      