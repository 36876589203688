<template>
  <div style="padding-top:85px; padding-bottom:55px; padding-left:0px">
  <div class="gridmenue-top">  
    <div class="grid-item-footer-text0 grid-item-left">
        <div>Plan</div> 
        <div style="color: #0099FF; margin-top:-10px">ahead</div>
    </div>
    <div class="gridmenueitems">
      <div class="grid-item1">
        <div class="grid-item-footer-text1 ">About</div>
        <div class="grid-item-footer-text2" style="padding-top:12px; cursor: pointer;" @click="this.$store.state.settings.introPageToShow = 4">Über Annucal</div>
      </div>
      <div class="grid-item1">
        <div class="grid-item-footer-text1">Support</div>
        <div class="grid-item-footer-text2" style="padding-top:12px; cursor: pointer;">Videoanleitung</div>
      </div>
      <div class="grid-item2">
        <div class="grid-item-footer-text1">Sicherheit</div>
        <div class="grid-item-footer-text2"  style="padding-top:12px; cursor: pointer;" @click="this.$store.state.settings.introPageToShow = 3">Datenschutz</div>
      </div>
      <div class="grid-item2">
        <div class="grid-item-footer-text1" >Kontakt</div>
        <div class="grid-item-footer-text2"  style="padding-top:12px; cursor: pointer;" @click="this.$store.state.settings.introPageToShow = 6">Kontakt</div>
        <div class="grid-item-footer-text2" style="padding-top:3px; cursor: pointer;" @click="this.$store.state.settings.introPageToShow = 5">Impressum</div>
      </div>
    </div>
 
  </div>  
  <div class="gridmenue-bottom" style="padding-top:15px; ">
    <div class="grid-item-left" >
      <div class="grid-item-footer-text3" >Wir sorgen dafür, dass du nie den</div>
      <div class="grid-item-footer-text3"  >Überblick verlierst.</div>
    </div>
  <div class="grid-item-left" >
  <div class="grid-item-footer-text2" style="
      height: 100%;
      display: flex;
      padding-right:10px;
      justify-content: end;
      align-items: flex-end;
  ">
    Annucal © 2023
  </div>
  </div>
  </div>  
  <div>
    
    
  </div>
</div>
    
</template>

<style scoped>

.gridmenue-top {
  display: grid;
  padding-left: 35px;
  padding-right: 5px;
  grid-template-columns: 35% 65%;
}
.gridmenue-bottom {
  display: grid;
  padding-left: 35px;
  margin-right: 35px;
  grid-template-columns: 50% 50%;
}
.grid-item-left {
  background-color: rgba(255, 255, 255, 0.8);
}


@media (min-width: 1200px){

  .grid-item1 {
  background-color: rgba(255, 255, 255, 0.8);
}

.grid-item2 {
  background-color: rgba(255, 255, 255, 0.8);
}
  .gridmenueitems {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%  ;
    padding-left:35px;
  }

  .grid-item-footer-text1{
  text-align: left;
  font-weight: 900;
  text-align: left;
  padding-top:5px;
  font-family: 'Roboto-Black';
  font-size: large;
  color: #0b3558;
  
}

}

@media (max-width: 1200px){

  .grid-item1 {
  background-color: rgba(255, 255, 255, 0.8);
  
}
.grid-item2 {
  background-color: rgba(255, 255, 255, 0.8);
  padding-top:35px;
}
  .gridmenueitems {
    display: grid;
    grid-template-columns: 50% 50%  ;
    padding-left:15px;
  }

  .grid-item-footer-text1{
  text-align: left;
  font-weight: 900;
  text-align: left;
  padding-top:5px;
  font-family: 'Roboto-Black';
  font-size: large;
  color: #0b3558;
}
  
}


.gridfooter  {
    font-size: 10px;
    padding-top:25px;
    color: #0b3558;
    background: #ffffff;
    text-align: left;
}

.grid-item-footer-text0{
  text-align: right;
  font-weight: 900;
  text-align: left;
  font-family: 'Roboto-Black';
  font-size: xx-large;
  color: #0b3558;
}


.grid-item-footer-text2{
  text-align: left;
  font-weight: 500;
  font-family: 'Roboto-light';
  font-size: small;
  color: #0b3558;
}
.grid-item-footer-text3{
  text-align: left;
  font-weight: 500;
  font-family: 'Roboto-light';
  font-size: small;
   color: #0b3558;  
}

</style>