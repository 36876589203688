
var events = []

export default {

    state(){
        return{
            items: [],
         
        }
    },
    actions:{
      getEvents({commit}){
        commit("setEvents",events);   
    }
  },

  mutations:{
    setEvents(state, events){
          state.items = events;
      }
  }
}

