import {db} from "../../db";
import { getDocs, collection, addDoc, setDoc, doc, deleteDoc } from "firebase/firestore";
import { useStore } from "vuex";

export default {

 
    actions:{
    
        async getEventsFromDB({commit}){
            
            const store = useStore();
            
            const snapshot = await getDocs(collection(db, "users", store.state.user.userID, "events"));
            
            const storedevents = snapshot.docs.map(doc => {
                const data =  doc.data();
                const id = doc.id;
                return {id, ...data};
            })
            commit("setStoredEvents",storedevents);
        },

        async deleteEvent(commit, data){
            
            await deleteDoc(doc(db, "users", data.userid, "events", data.eventidDB));
        },

        async createEvent(commit, data) {
            let newEvent = {
                color: data.color, 
                title: data.title,
                datefrom: data.datefrom,
                dateto: data.dateto,
                datefromJson: data.datefromJson ,
                datetoJson: data. datetoJson   
            }
            const docRef = await addDoc(collection(db, "users", data.userid, "events"), newEvent);
            return docRef
        },

        async updateEvent(commit,data){
            let newEvent = {
                color: data.color, 
                title: data.title,
                datefrom: data.datefrom,
                dateto: data.dateto,
                datefromJson: data.datefromJson ,
                datetoJson: data. datetoJson   
            }
            await setDoc(doc(db, "users", data.userid, "events", data.eventidDB), newEvent);
            //await setDoc(db.collection("users").document(data.userid).collection("events").document(data.eventidDB),newEvent)
        }
    },

    mutations:{
        setStoredEvents(state, storedevents){
           // if(state.items == undefined){
           //     state.items = storedevents;
           // }

            state.items = []

            for (let index = 0; index < storedevents.length; ++index){
                let newEvent = {
                    id: storedevents[index].datefrom,
                    eventidDB: storedevents[index].id,
                    userid: storedevents[index].userid,
                    color: storedevents[index].color, 
                    title: storedevents[index].title,
                    datefrom: storedevents[index].datefrom,
                    dateto: storedevents[index].dateto,
                    datefromJson: storedevents[index].datefromJson ,
                    datetoJson: storedevents[index].datetoJson    
                  }
                  state.items.push(newEvent)
            }
        }
    }
}
  
  