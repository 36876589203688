<template>
  <div >
    <div 
        id={item.id}
        class="popover day-grid-container"
          @mouseenter="handleShow($event), mouseenter(item)" 
          @click="click(item), toggle === false ? handleClick($event) : handleHide($event)"
          @mouseleave="mouseleave(item)"
          @mousedown="mousedown(item)"
          @mouseup="mouseup(item)"
          @mousemove="mousemove(item)"
        >
        
        <div class="day-item" v-if="item.name == 1">{{item.month}}</div>
        
        <div
          class="day-grid-container" 
          @dragstart="startDrag($event,item)"
          @drop="onDrop($event,item, events)"
          @dragenter.prevent
          @dragover.prevent
          v-bind:draggable= item.draggable
         >
         
               <!-- Border Top Start -->
               
                  <!-- Without red underline -->
                  <div class="item-small-top " v-if="item.id != this.$store.state.settings.currentRealDate && item.index != 31"
                    v-bind:style="{ backgroundColor: item.backColorWeekDay,
                    cursor: this.$store.state.settings.selectedCursor
                    }">
                
                    <Tooltip v-if="item.showTooltip" 
                        placement="top" 
                        :item=item 
                    />
                    <table v-if="!item.showTooltip" >
                      <tr>
                        <td class="weekday-number">&nbsp;{{item.name}}&nbsp;</td>
                        <td class="weekday-name">{{item.weekday}}</td>
                        </tr>
                    </table>                
                </div>

                 <!-- With red underline -->
               <div class="item-small-top red-underline" v-if="item.id == this.$store.state.settings.currentRealDate && item.index != 31"
                v-bind:style="{ backgroundColor: item.backColorWeekDay,
                cursor: this.$store.state.settings.selectedCursor
                }">
            
                <Tooltip v-if="item.showTooltip" 
                    placement="top" 
                    :item=item 
                />
                <table v-if="!item.showTooltip" >
                  <tr>
                    <td class="weekday-number">&nbsp;{{item.name}}&nbsp;</td>
                    <td class="weekday-name">{{item.weekday}}</td>
                    </tr>
                </table>                
            </div>
            <!-- Border Top End -->
     
            <!-- Border Bottom Start -->
            <div class="item-small-bottom" v-if="item.index == 31"
                v-bind:style="{ backgroundColor: item.backColorWeekDay,
                cursor: this.$store.state.settings.selectedCursor
                }">
            
                <Tooltip v-if="item.showTooltip" 
                    placement="top" 
                    :item=item 
                />
                <table v-if="!item.showTooltip" >
                    <td class="weekday-number">&nbsp;{{item.name}}&nbsp;</td>
                    <td class="weekday-name">{{item.weekday}}</td>
                </table>
            </div>
            <!-- Border Bottom End -->
            
            <!-- Border Top Start -->
            <div id="itemlarge" class="item-large-top" v-if="item.index != 31"
              v-bind:style="{ 
                backgroundColor: item.backColor, 
                backgroundImage: `url(${require('@/assets/images/kw/' + returnbgimage(item) )})`,
                cursor: this.$store.state.settings.selectedCursor
                }"
              >
              <div class="SetEllipsis">&nbsp; {{item.eventText}}</div>
            </div>
            <!-- Border Top End -->

            <!-- Border Bottom Start -->
            <div id="itemlarge" class="item-large-bottom" v-if="item.index == 31"
              v-bind:style="{ 
                backgroundColor: item.backColor, 
                backgroundImage: `url(${require('@/assets/images/kw/' + returnbgimage(item) )})`,
                cursor: this.$store.state.settings.selectedCursor
                }"
              >
              <div class="SetEllipsis">&nbsp; {{item.eventText}}</div>
            </div>
            <!-- Border Bottom End -->

            
        </div> 
    </div>

    <div class="popoverPanel"
    @keyup.enter="saveChanges(this.$store.state.settings.eventColor, this.$store.state.settings.eventName, $event)">
      <div class="popoverArrow" data-popper-arrow></div>
      <div style="
          color:#0b3558;
          user-select: none;
          font-size: medium; 
          text-align: left;
          padding-left: 5px;
          padding-right: 5px;
          padding-top: 5px;
          font-weight: 800;
          ">
      <p  
          style="
          user-select: none;
          text-align: left;
          padding-left: 15px;
          padding-right: 11px;
          padding-top: 5px;
          color:#0b3558;
          font-size: large;
          font-weight: 500;
          text-align: left;
          font-family: 'Roboto-Medium';
          
          ">{{this.$store.state.settings.SlotHeader}}</p>


        <div 
            style="
            user-select: none;
            text-align: left;
            padding-top: 15px;
            padding-left: 17px;
            padding-right: 10px;
            padding-bottom: 0px;
            color:#0b3558;
            font-size: small;
            font-weight: 600;
            text-align: left;
            font-family: 'Roboto-Light';
            "
            
          >Start </div>

          <div class="date" v-if="!this.$store.state.settings.ShowDeleteButton">
            <div
                style="
                font-size: large; 
                font-weight: 600;
                text-align: left;
                padding-top:0px;
                padding-left: 17px;
                color:#0b3558;
                font-size: medium;
                font-weight: 500;
                text-align: left;
                font-family: 'Roboto-Medium';
                ">
                <p >{{item.dateFormated_De}}</p>
            </div>
        </div>
        <div class="date" v-if="this.$store.state.settings.ShowDeleteButton">
            <div
                style="
                font-size: large; 
                font-weight: 600;
                text-align: left;
                padding-top:0px;
                padding-left: 17px;
                color:#0b3558;
                font-size: medium;
                font-weight: 500;
                text-align: left;
                font-family: 'Roboto-Medium';
                ">
                <p >{{this.$store.state.settings.currentdatefrom}}</p>
            </div>
        </div>
        <div 
            style="
            color:#0b3558;
            user-select: none;
            font-size: smaller; 
            text-align: left;
            padding-top: 15px;
            padding-left: 17px;
            padding-right: 10px;
            font-weight: 500;"
          ></div>
        


          
          <input 
                class="input is-large"   
                placeholder="Titel eintragen" 
                v-model="this.$store.state.settings.eventName"
                :style="{backgroundColor: this.$store.state.settings.eventColor,  color: 'black'}" 
                style="
                color:#0b3558;
                font-size: medium;
                font-weight: 800;
                text-align: left;
                font-family: 'Roboto-Light';
                border-radius: 3px;
                margin-left: 16px;
                margin-top: 5px;
                margin-right: 20px;
                width: 86%;"
                
                >
        <div 
            style="    
            user-select: none;
            padding-left: 19px;
            padding-top: 26px;
            font-weight: 400;
            color:#0b3558;
            font-size: small;
            font-weight: 600;
            text-align: left;
            font-family: 'Roboto-Light';
            "
          >Farbe </div>

          <div class="grid-container" v-if="this.$store.state.settings.ShowDeleteButton">
                <div @click="changeEventColor('#FF9933')" class="color-selector2" style="background-color: #FF9933"></div>
                <div @click="changeEventColor('#FF6699')" class="color-selector2"  style="background-color: #FF6699"></div>
                <div @click="changeEventColor('#CC9933')" class="color-selector2" style="background-color: #CC9933"></div>
                <div @click="changeEventColor('#33CC66')" class="color-selector2"  style="background-color: #33CC66"></div>
                <div @click="changeEventColor('#FF6633')" class="color-selector2" style="background-color: #FF6633"></div>
                <div @click="changeEventColor('#CC66CC')" class="color-selector2"  style="background-color: #CC66CC"></div>
                <div @click="changeEventColor('#999966')" class="color-selector2"  style="background-color: #999966"></div>
                <div @click="changeEventColor('#009966')" class="color-selector2"  style="background-color: #009966"></div>
                
                
          </div> 

          <div class="grid-container" v-if="!this.$store.state.settings.ShowDeleteButton">
                <div @click="changeEventColor('#FF9933')" class="color-selector" style="background-color: #FF9933"></div>
                <div @click="changeEventColor('#FF6699')" class="color-selector"  style="background-color: #FF6699"></div>
                <div @click="changeEventColor('#CC9933')" class="color-selector" style="background-color: #CC9933"></div>
                <div @click="changeEventColor('#33CC66')" class="color-selector"  style="background-color: #33CC66"></div>
                <div @click="changeEventColor('#FF6633')" class="color-selector" style="background-color: #FF6633"></div>
                <div @click="changeEventColor('#CC66CC')" class="color-selector"  style="background-color: #CC66CC"></div>
                <div @click="changeEventColor('#999966')" class="color-selector"  style="background-color: #999966"></div>
                <div @click="changeEventColor('#009966')" class="color-selector"  style="background-color: #009966"></div>
                
                
          </div> 
            
        <div class="modal-card-foot go-right">
            <div >
        </div>
        <p v-if="this.$store.state.settings.ShowDeleteButton"
          style="
          
          padding-right:8px;
            color:#0b3558;
         
          ">
          <button @click="deleteEvent()" class="button  is-small buttonbottom_save"
          style="color:red">Löschen</button>
        </p>

        <p v-if="!this.$store.state.settings.ShowDeleteButton"
          style="
          
          padding-left: 82px;
          ">
          
        </p>

        <button @click="handleHide($event)" class="button is-small buttonbottom_close"
        style="
          margin-right: 8px;
          "
          >Abbrechen</button>

      

        <button @click="saveChanges(this.$store.state.settings.eventColor, this.$store.state.settings.eventName, $event)"
        style="
        background-color: #0099FF;
        color:white;
        "
        class="button  is-small buttonbottom_save">Speichern</button>
        </div>

    </div>
  </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { defineComponent, ref, computed } from 'vue'
import { createPopper } from '@popperjs/core'
import Tooltip from '../components/ToolTip.vue';
import calenderCore from "../composition/calenderCore";
import { useVuelidate } from '@vuelidate/core'
import { required,maxLength } from '@vuelidate/validators'
import { useToast } from "vue-toastification"
const toast = useToast();

export default defineComponent({

  name: 'Popover',
  props: {
    placement: String,
    item:Object,
    ModalPropStartDate: String,
    ModalPropEventColor: String,
    ModalPropFunctionsaveChanges: Function
  },
  components: {
    Tooltip
  },
  data () {
      return {
        Titel: "",
        date: null,
        insertEventisOpen: false,
        deleteEventisOpen: false,
        backColor: "#ffffff",
        eventName: "",
        selectedDate: "",
        name:"",
        backColorTemp: "#ffffff",
        backColorWeekDayTemp: "#ffffff",
        currentEventText: "",
        mouseDownDate: "",
        selectedEvent: "",
        deleteBackgroundColor: "#00ff00",
        selected_dateFormatedJson: "",
        selectedDay: null,
        v$: useVuelidate()
      }
    },

    computed:{

      events(){
          return this.$store.state.events.items;
        }
      },
      items(){
            return this.$store.state.calender.items;
        },

       async created(){
        /*
          if(this.$store.state.settings.EventsLoaded == false){
            this.calenderCore1 = calenderCore();
            await this.$store.dispatch("getEvents").then (
            setTimeout(() => {  this.calenderCore1.updateCalender(); }, 1000)
          )
          console.log("running")
          this.$store.state.settings.EventsLoaded = true
          }
          */
        
      },

  methods:{

    returnbgimage(day){
      if(this.$store.state.settings.showKalenderwochen == true){
        return day.kw;
      }
      else{
        return("empty.svg");
      }
    },

    click(item){
      if(this.SelectedDateHasEvent(item.id) != false){
        // Edit Event
        this.$store.state.settings.eventName = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).title;
        this.$store.state.settings.eventColor =  item.backColor;
        this.$store.state.settings.SlotHeader = "Ereignis bearbeiten";
        this.$store.state.settings.ShowDeleteButton = true;
        this.$store.state.settings.SlotMode = "Edit";
        this.$store.state.settings.currentEventId = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).id;
        this.$store.state.settings.currentEventIdDB = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).eventidDB
        this.deleteBackgroundColor = item.backColor ;
        this.deleteEventisOpen = true;
        this.selectedEvent = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id));
        this.selectedDay = null;
        this.$store.state.settings.currentdatefrom = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).datefrom
        this.$store.state.settings.currentdateto = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).dateto
        this.$store.state.settings.currentdatefromJson = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).datefromJson
        this.$store.state.settings.currentdatetoJson = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).datetoJson
      }
      // Insert Event
      else{
          this.$store.state.settings.eventName = "";
          this.$store.state.settings.eventColor =  '#ebebeb';
          this.$store.state.settings.SlotHeader = "Neues Ereignis";     
          this.$store.state.settings.ShowDeleteButton = false; 
          this.$store.state.settings.SlotMode = "New";
          this.$store.state.settings.currentEventIdDB = "No Event"
          this.insertEventisOpen = true;
          this.selectedDate = item.dateFormated_De;
          this.$store.state.settings.slectedDayId = item.id;
          this.selected_dateFormatedJson = item.dateFormated_Json;
          this.selectedDay = item;
      }
    },

    doubleclick(item){
    
      if(this.SelectedDateHasEvent(item.id) != false){
        // Edit Event
        this.$store.state.settings.eventName = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).title;
        this.$store.state.settings.eventColor =  item.backColor;
        this.$store.state.settings.SlotHeader = "Ereignis bearbeiten";
        this.$store.state.settings.ShowDeleteButton = true;
        this.$store.state.settings.SlotMode = "Edit";
        this.$store.state.settings.currentEventId = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).id;
        this.$store.state.settings.currentEventIdDB = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).eventidDB
        this.deleteBackgroundColor = item.backColor ;
        this.deleteEventisOpen = true;
        this.selectedEvent = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id));
        this.selectedDay = null;
        this.$store.state.settings.currentdatefrom = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).datefrom
        this.$store.state.settings.currentdateto = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).dateto
        this.$store.state.settings.currentdatefromJson = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).datefromJson
        this.$store.state.settings.currentdatetoJson = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).datetoJson
      }
      // Insert Event
      else{
          this.$store.state.settings.eventName = "";
          this.$store.state.settings.eventColor =  '#ebebeb';
          this.$store.state.settings.SlotHeader = "Neues Ereignis";     
          this.$store.state.settings.ShowDeleteButton = false; 
          this.$store.state.settings.SlotMode = "New";
          this.$store.state.settings.currentEventIdDB = "No Event"
          this.insertEventisOpen = true;
          this.selectedDate = item.dateFormated_De;
          this.$store.state.settings.slectedDayId = item.id;
          this.selected_dateFormatedJson = item.dateFormated_Json;
          this.selectedDay = item;
      }
    
  },

  mousedown(item){
    
    this.$store.state.settings.ismousedown = true;
    const terminID =  this.SelectedDateHasEvent(item.id) ;
    
    if(terminID != false)
    {
      if(this.TerminHasOnlyOneDay(terminID, item.id)){
        this.$store.state.settings.mouseDownEventStartDate = item.id;
        this.$store.state.settings.mouseDownEventEndDate = item.id
        this.$store.state.settings.selectedEventIDtoChangeDate = terminID;
        this.$store.state.settings.FirstDayOfEventSelected  = true;
        this.$store.state.settings.LastDayOfEventSelected = true;
      }
      else if (this.IsFirstDayOfEvent(terminID, item.id) == true){
        this.$store.state.settings.mouseDownEventStartDate = item.id;
        this.$store.state.settings.selectedEventIDtoChangeDate = terminID;
        this.$store.state.settings.FirstDayOfEventSelected = true;
        this.$store.state.settings.LastDayOfEventSelected = false;
        
      }
      else if(this.IsLastDayOfEvent(terminID, item.id) == true){
        this.$store.state.settings.mouseDownEventEndDate = item.id;
        this.$store.state.settings.selectedEventIDtoChangeDate = terminID;
        this.$store.state.settings.FirstDayOfEventSelected = false;
        this.$store.state.settings.LastDayOfEventSelected = true;
      }
    }
  },

  mouseup(item){
    this.$store.state.settings.ismousedown = false;
    this.backColor = item.backColor;
    this.$store.state.settings.mouseDownEventStartDate = false;
    this.$store.state.settings.mouseDownEventEndDate = false;  
    this.$store.state.settings.addDayOnEvent = false;    
    this.$store.state.settings.FirstDayOfEventSelected = false;
    this.$store.state.settings.LastDayOfEventSelected = false;
    this.calenderCore1.updateCalender();

    if(this.$store.state.settings.eventNeedsSaving != "no"){
      for (let index = 0; index < this.events.length; ++index) {
          if (this.events[index].eventidDB == this.$store.state.settings.eventNeedsSaving)
          {
            if(this.$store.state.settings.Authenticated == true){
              let newEvent = {
              userid: this.$store.state.user.userID,
              eventidDB : this.events[index].eventidDB,
              color: this.events[index].color, 
              title: this.events[index].title,
              datefrom: this.events[index].datefrom,
              dateto: this.events[index].dateto,
              datefromJson: this.events[index].datefromJson ,
              datetoJson: this.events[index].datetoJson   
            } 
            this.$store.dispatch("updateEvent", newEvent); 
            }  
        }
      }

      this.$store.state.settings.eventNeedsSaving = "no"
    }
  },

  mouseenter(item){
    
    if(this.$store.state.settings.ismousedown == false){
        if(this.$store.state.settings.selectedCursor != item.cursor){
          this.$store.state.settings.selectedCursor = item.cursor;
        }
    }
  
    //Highlight day under MouseMove
    this.backColorTemp = item.backColor;
    this.backColorWeekDayTemp = item.backColorWeekDay;
    item.backColorWeekDay = '#ffff00';

    if(this.$store.state.settings.LastDayOfEventSelected == true){
      this.addDayToEventBottom(item);
    }
    else if(this.$store.state.settings.FirstDayOfEventSelected == true){
      this.addDayToEventTop(item);
    }
  },

  mouseleave(item){
    //item.backColor = this.backColorTemp;
    
    item.backColorWeekDay = this.backColorWeekDayTemp;
    //if(this.$store.state.settings.ismousedown == true){
     
     if(this.$store.state.settings.FixedWithMousUp == false){
      this.calenderCore1.updateCalender();
      this.$store.state.settings.FixedWithMousUp = true
     }
      
      
    //}
    
  },

  mousemove(item)
  {
    if(this.$store.state.settings.ismousedown == true)
    {
      if(item.backColor == this.backColor)
      {
        item.backColor = item.backColorWeekDay;
      }

      if(this.$store.state.settings.ctrlPressed == true){
        this.calenderCore1.updateCalender();
      }
    }
  },

  addDayToEventBottom(item){
    if(this.$store.state.settings.ismousedown == true && this.$store.state.settings.ctrlPressed == false)
    {
      this.$store.state.settings.selectedCursor = "ns-resize";
      //Add day to event
      if(this.$store.state.settings.mouseDownEventEndDate != false){    
        
        var dt1 = new Date(this.$store.state.settings.mouseDownEventEndDate);
        var dt2 = new Date(item.id);
        if(Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24)) > -1){
          for (let index = 0; index < this.events.length; ++index) {
            if (this.events[index].id == this.$store.state.settings.selectedEventIDtoChangeDate)
            {
                if(this.IsLastEventDateWithinNextEvent(this.events[index].id,item)== false){
                    this.events[index].dateto = item.id;
                    this.events[index].datetoJson =  item.dateFormated_Json ,
                    this.calenderCore1.updateCalender();
                    this.$store.state.settings.addDayOnEvent = false;
                    this.$store.state.settings.eventNeedsSaving = this.events[index].eventidDB
              }
            }
          }
        }
        //Remove day from event
        else if(Math.floor((Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) - Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) ) /(1000 * 60 * 60 * 24)) > 0){
          for (let index = 0; index < this.events.length; ++index) {
            if (this.events[index].id == this.$store.state.settings.selectedEventIDtoChangeDate)
            {
              var dt_from = new Date (this.events[index].datefrom);
              var dt_to = new Date(item.id);
              
              if(Math.floor((Date.UTC(dt_from.getFullYear(), dt_from.getMonth(), dt_from.getDate()) - Date.UTC(dt_to.getFullYear(), dt_to.getMonth(), dt_to.getDate()) ) /(1000 * 60 * 60 * 24)) < 1){
                  this.events[index].dateto = item.id;
                  this.events[index].datetoJson =  item.dateFormated_Json;
                  this.calenderCore1.updateCalender();
                  this.$store.state.settings.eventNeedsSaving = this.events[index].eventidDB
              } 
            }
          }
        }
      } 
    }

  },

 

  addDayToEventTop(item){
    if(this.$store.state.settings.ismousedown == true && this.$store.state.settings.ctrlPressed == false)
    {
      //Add day to event
      this.$store.state.settings.selectedCursor = "ns-resize";
      if(this.$store.state.settings.mouseDownEventStartDate != false){    
          var dt1 = new Date(this.$store.state.settings.mouseDownEventStartDate);
          var dt2 = new Date(item.id);

          if(Math.floor((Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) - Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) ) /(1000 * 60 * 60 * 24)) >   -1){
            for (let index = 0; index < this.events.length; ++index) {
              if (this.events[index].id == this.$store.state.settings.selectedEventIDtoChangeDate)
              {
                
                  if(this.IsFirstEventDateWithinPreviousEvent(this.events[index].id,item)== false){
                      this.events[index].datefrom = item.id;
                      this.events[index].datefromJson = item.dateFormated_Json;
                      this.$store.state.settings.mouseDownEventStartDate = item.id;
                      this.calenderCore1.updateCalender();
                      this.$store.state.settings.addDayOnEvent = false;
                      this.$store.state.settings.eventNeedsSaving = this.events[index].eventidDB
                  }
              }
            }
          }
          //Remove day from event
          
          else if(Math.floor((Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) - Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) ) /(1000 * 60 * 60 * 24)) < 0){
            for (let index = 0; index < this.events.length; ++index) {
              if (this.events[index].id == this.$store.state.settings.selectedEventIDtoChangeDate)
              {
                var dt_from = new Date (item.id);
                var dt_to = new Date(this.events[index].dateto);
                
                if(Math.floor((Date.UTC(dt_from.getFullYear(), dt_from.getMonth(), dt_from.getDate()) - Date.UTC(dt_to.getFullYear(), dt_to.getMonth(), dt_to.getDate()) ) /(1000 * 60 * 60 * 24)) < 1){
                    this.events[index].datefrom = item.id;
                    this.events[index].datefromJson = item.dateFormated_Json;
                    this.$store.state.settings.mouseDownEventStartDate = item.id;
                    this.calenderCore1.updateCalender();
                    this.$store.state.settings.eventNeedsSaving = this.events[index].eventidDB
                } 
              }
            }
          }
        } 
    }

  },

  ChangeEventNameAndColor(Eventcolor, EventName){
    for (let index = 0; index < this.events.length; ++index) {
        if (this.events[index].id == this.$store.state.settings.currentEventId)
        {
          this.events[index].title = EventName
          this.events[index].color = Eventcolor
          this.events[index].datefrom = this.$store.state.settings.currentdatefrom
          this.events[index].dateto = this.$store.state.settings.currentdateto
          this.events[index].datefromJson = this.$store.state.settings.currentdatefromJson
          this.events[index].datetoJson = this.$store.state.settings.currentdatetoJson
        }
    } 
  },

  async saveChanges(Eventcolor, EventName, e){

    this.eventName = EventName
    var isValid = await this.v$.$validate();

    if(this.$store.state.settings.eventColor == "#ebebeb"){
      isValid = false;
      toast.error("Bitte eine Farbe auswählen")
    }

    if (isValid){
        let tempdocid = Math.floor(Math.random() * 100000000)
        if(this.$store.state.settings.SlotMode == "Edit"){
            this.ChangeEventNameAndColor(Eventcolor, EventName);

            if(this.$store.state.settings.Authenticated == true){
              console.log("store now")
              let newEvent = {
                userid: this.$store.state.user.userID,
                eventidDB : this.$store.state.settings.currentEventIdDB,
                color: Eventcolor, 
                title: EventName,
                datefrom: this.$store.state.settings.currentdatefrom,
                dateto: this.$store.state.settings.currentdateto,
                datefromJson: this.$store.state.settings.currentdatefromJson ,
                datetoJson: this.$store.state.settings.currentdatetoJson    
              }
              this.$store.dispatch("updateEvent", newEvent);
            }
        }
        else{
          if(this.$store.state.settings.Authenticated == true){
            if(this.$store.state.settings.currentEventIdDB == "No Event"){
              let newEvent = {
                userid: this.$store.state.user.userID,
                color: Eventcolor, 
                title: EventName,
                datefrom: this.$store.state.settings.slectedDayId,
                dateto: this.$store.state.settings.slectedDayId,
                datefromJson: this.selected_dateFormatedJson ,
                datetoJson: this.selected_dateFormatedJson    
              }
              const docid =  await this.$store.dispatch("createEvent", newEvent);
              tempdocid = docid.id
            }
          }
          this.events.push({
            id: tempdocid,
            eventidDB : tempdocid,
            color: Eventcolor, 
            title: EventName,
            datefrom: this.$store.state.settings.slectedDayId,
            dateto: this.$store.state.settings.slectedDayId,
            datefromJson: this.selected_dateFormatedJson,
            datetoJson: this.selected_dateFormatedJson  
          })
        }
        if(this.selectedDay != null){
            this.selectedDay.cursor = "s-resize";
        } 
        this.calenderCore1.updateCalender();
        this.handleHide(e);
      }
      else{
        if(this.v$.eventName.required.$invalid){
          toast.error("Bitte einen Titel für das Ereignis eintragen")
        }
        else if(this.v$.eventName.maxLength.$invalid){
          toast.error("Der Eventname darf aus max. 25 Zeichen bestehen")
        }
      }
  },

  deleteEvent(){
    for (let index = 0; index < this.events.length; ++index){
      if (this.events[index].id == this.$store.state.settings.currentEventId){
          this.events.splice(index, 1); 
          this.calenderCore1.updateCalender();
          if(this.$store.state.settings.Authenticated == true){
            let eventToDelete = {
              userid: this.$store.state.user.userID,
              eventidDB : this.$store.state.settings.currentEventIdDB, 
            }
            this.$store.dispatch("deleteEvent", eventToDelete);
          }
      }
    }
    this.calenderCore1.updateCalender();
    this.handleHide();
  },

    updateEvents()
    {
        for (let index = 0; index < this.items.length; ++index){
          this.updateSingleDay(this.items[index]);
        }
    },

    updateSingleDay(day){
      var dayHasEvent = false;
      for (let index = 0; index < this.events.length; ++index) {
        if((day.id >= this.events[index].datefrom && day.id <= this.events[index].dateto))
        {
          day.backColor = this.events[index].color;
          day.eventText = this.events[index].title;
          dayHasEvent = true;
        }
      }
      if (dayHasEvent == false){
          //day.backColor = day.backColorWeekDay;
          day.eventText = "";
          if(day.weekday == "So" || day.weekday == "Sa"){
                day.backColor = this.$store.state.settings.BackColorWeekend;
                day.dayHasEvent = true;
            }
            else{
                day.backColor = this.$store.state.settings.BackColorNormalDay;              
            }
      }
    },

    GetSelectedEvent(eventID){
      for (let index = 0; index < this.events.length; ++index) {
          if (this.events[index].id == eventID)
          {
              return this.events[index];   
          }
      } 
    },


    SelectedDateHasEvent(selectedDate){
      for (let index = 0; index < this.events.length; ++index) {
        if (selectedDate >= this.events[index].datefrom && selectedDate <= this.events[index].dateto)
        {
          return this.events[index].id;
        }
      }
      return false;
    },

    TerminHasOnlyOneDay(eventID){

      for (let index = 0; index < this.events.length; ++index){
        if (this.events[index].id == eventID){
          var dt1 = new Date(this.events[index].datefrom);
          var dt2 = new Date(this.events[index].dateto);

          if(Math.floor((Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) - Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) ) /(1000 * 60 * 60 * 24)) == 0){
            return true;
          }
          else{
            return false;
          }
        }
      }
    },

    IsFirstDayOfEvent(eventID, selectedDate){
      for (let index = 0; index < this.events.length; ++index) {
        
        if (this.events[index].id == eventID)
        {
          if(this.events[index].datefrom == selectedDate){
            return true;
          }
        }
      }
      return false;
    },

    IsLastDayOfEvent(eventID, selectedDate){
      for (let index = 0; index < this.events.length; ++index) {
        if (this.events[index].id == eventID)
        {
          if(this.events[index].dateto == selectedDate){
            return true;
          }
        }
      }
      return false;
    },

    DayDifference(day1, day2){
      var dt1 = new Date(day1);
      var dt2 = new Date(day2);
      return Math.floor((Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) - Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) ) /(1000 * 60 * 60 * 24));
    },

    IsLastEventDateWithinNextEvent(eventID,currentItem){
      
      // Find selected event
      for (let index1 = 0; index1 < this.events.length; ++index1) {
          if (this.events[index1].id == eventID)
          {
            // Get StartDate and EndDate from selected event
            var dt0 = new Date(this.events[index1].datefrom);
            var dt1 = new Date(currentItem.id);

            // Run again through all events
            for (let index = 0; index < this.events.length; ++index){

                // only process the other events and not the selected one
                if(eventID != this.events[index].id){

                    // get DateFrom for the current Event
                    var dt2 = new Date(this.events[index].datefrom);

                    // Is DateFrom of the selected event before the DateFrom of the current event
                    
                    if(this.DayDifference(dt0, dt2) < 0){
                        if(this.DayDifference(dt1, dt2) > -1){
                            return true;
                        }
                    }
                }
            }
            return false;
        }
      }
    },
    IsFirstEventDateWithinPreviousEvent(eventID,currentItem){
      
      // Find selected event
      for (let index1 = 0; index1 < this.events.length; ++index1) {
          if (this.events[index1].id == eventID)
          {
            // Get StartDate and EndDate from selected event
            var dt0 = new Date(currentItem.id);
            var dt1 = new Date(this.events[index1].dateto);

            // Run again through all events
            for (let index = 0; index < this.events.length; ++index){

                // only process the other events and not the selected one
                if(eventID != this.events[index].id){

                    // get DateFrom for the current Event
                    var dt2 = new Date(this.events[index].dateto);

                    // Is DateFrom of the selected event before the DateFrom of the current event
                    if(this.DayDifference(dt0, dt2) < 1){
                        if(this.DayDifference(dt1, dt2) > -1){
                            return true;
                        }
                    }
                }
            }
            return false;
        }
      }
    },

    closePopup(){
      this.toggle.value = false;
    },

    changeEventColor(selectedColor){
      this.$store.state.settings.eventColor = selectedColor;
    }
  },

  validations () {
    return {
      eventName: { 
        required,
        maxLength: maxLength(25), 
      }
    }
  },

  setup(props) {
    const button = ref(null)
    const popover = ref(null)
    const toggle = ref(false)
    const store = useStore();
    const { state } = store;
    const calenderCore1 = calenderCore();      
    const isAuthenticated = computed(() => store.getters["user/isAuthenticated"]);
    

    const popperInstance = computed(() => {
      return createPopper(button.value, popover.value, {
        placement: props.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 0],
            },
          },
        ],
        strategy: 'absolute'
         
      })
    })

    const insertElement = (btn, tip) => {
      button.value = btn
      popover.value = tip
      
    }

    const handleShow = (e) => {
      if(button.value === null && popover.value === null) {
        insertElement(e.target, e.target.parentElement.querySelector('.popoverPanel'));
      }
    }

    const handleClick = (e) => {
      if(state.settings.popOverCalenderOpen == false){
        
        if(button.value === null && popover.value === null) {
        insertElement(e.target, e.target.parentElement.querySelector('.popoverPanel'))
        }
        popover.value.setAttribute('data-show', '')
        popperInstance.value.update()
        toggle.value = true
        state.settings.popOverCalenderOpen = true
        //e.target.parentElement.style.borderTop = "1px solid rgba(66, 66, 66, 0.5)";
        //e.target.parentElement.style.borderLeft = "1px solid rgba(66, 66, 66, 0.5)";
        //e.target.parentElement.style.borderBottom = "1px solid rgba(66, 66, 66, 0.5)";

        //e.target.parentElement.getElementsByClassName( 'item-large' )[0].style.borderTop = "0px";
        //e.target.parentElement.getElementsByClassName( 'item-small' )[0].style.borderTop = "0px"; 
      }
    }

    const handleHide = (e) => {
      if(button.value === null && popover.value === null) {
        insertElement(e.target, e.target.parentElement.querySelector('.popoverPanel'))
      }
      popover.value.removeAttribute('data-show');
      //e.target.parentElement.style.border = "0px solid rgba(0,0,0,.5)";
      //e.target.parentElement.getElementsByClassName( 'item-large' )[0].style.borderTop = "1px solid rgb(197, 194, 194)"
      //e.target.parentElement.getElementsByClassName( 'item-small' )[0].style.borderTop = "1px solid rgb(197, 194, 194)"
      toggle.value = false
      state.settings.popOverCalenderOpen = false
    }

    const startDrag = (event, item) =>{
      state.settings.isdragging = true;
      state.settings.selectedCursor = "alias";
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('itemID', item.id);
      event.dataTransfer.effectAllowed = false;
      
      
      //var img = document.createElement("img");
      //img.src = "http://kryogenix.org/images/hackergotchi-simpler.png";
      //event.dataTransfer.setDragImage(img, 0, 0);
    }

   const getMonthName = (month) =>{
    
    switch(month) {
    case "01":
      return "Januar"
    case "02":
      return "February"
    case "03":
      return "March"
    case "04":
      return "April"
    case "05":
      return "May"
    case "06":
      return "June"
    case "07":
      return "July"
    case "08":
      return "August"
    case "09":
      return "Sepetember"
    case 10:
      return "October"
    case 11:
      return "November"
    case 12:
      return "December"
   
}
   }

    const onDrop = (event, item, events) => {
      state.settings.ctrlPressed = false;
      state.settings.ismousedown = false;
      state.settings.selectedCursor = "default";
      if(item.dayHasEvent == false){
        const itemID = event.dataTransfer.getData('ItemID')
      for (let index = 0; index < events.length; ++index) {
        if (itemID >= events[index].datefrom && itemID <= events[index].dateto)
        {
          let date_1 = new Date(events[index].dateto);
          let date_2 = new Date(events[index].datefrom);
          let difference = date_1.getTime() - date_2.getTime();
          let TotalDays = Math.ceil(difference / (1000 * 3600 * 24) );
          const newStartDate = new Date(item.id);
          const newEndDate = new Date(item.id);
          newEndDate.setDate(newEndDate.getDate() + TotalDays)
          let correctEndDate = new Date(item.id)

          let newYearFrom = newStartDate.getYear() + 1900
          let newMonthFrom = newStartDate.getMonth() + 1
          let newDayFrom = newStartDate.getDate()

          let newYearTo = newEndDate.getYear() + 1900
          let newMonthTo = newEndDate.getMonth() + 1
          let newDayTo = newEndDate.getDate()

          if(newMonthFrom < 10) newMonthFrom = "0"+newMonthFrom;
          if(newDayFrom < 10) newDayFrom = "0"+newDayFrom;
          if(newMonthTo < 10) newMonthTo = "0"+newMonthTo;
          if(newDayTo < 10) newDayTo = "0"+newDayTo;
       
          

          let insert = true;

          for (var d = newStartDate; d <= newEndDate; d.setDate(d.getDate() + 1)) {
            if(insert == false){
              break;
            }
            else{
              newYearTo = d.getYear() + 1900
              newMonthTo = d.getMonth() + 1
              newDayTo = d.getDate()
              if(newMonthTo < 10) newMonthTo = "0"+newMonthTo;
              if(newDayTo < 10) newDayTo = "0"+newDayTo;

              for (let index3 = 0; index3 < events.length; ++index3){
                if(insert == true){
                  if(events[index].id != events[index3].id){
                    if(((newYearTo + "-" + newMonthTo + "-" + newDayTo) == events[index3].datefrom)){
                    insert = false;
                    d.setDate(d.getDate() - 1)
                    correctEndDate = d
                  }
                  }
                  else{
                    correctEndDate = d
                  }
                }
              }
            }
          }
          
          correctEndDate.setDate(correctEndDate.getDate() - 1)

          newYearTo = correctEndDate.getYear() + 1900
          newMonthTo = correctEndDate.getMonth() + 1
          newDayTo = correctEndDate.getDate()

          if(newMonthTo < 10) newMonthTo = "0"+newMonthTo;
          if(newDayTo < 10) newDayTo = "0"+newDayTo;

          events.push({
            id: Math.floor(Math.random() * 100000000),
            eventidDB : events[index].eventidDB,
            color: events[index].color, 
            title: events[index].title,
            description: events[index].description,
            datefrom: newYearFrom + "-" + newMonthFrom + "-" + newDayFrom,
            dateto: newYearTo + "-" + newMonthTo + "-" + newDayTo,
            datefromJson: getMonthName(newMonthFrom) + " " + newDayFrom + ", " + newYearFrom + " 12:00:00 UTC" ,
            datetoJson: getMonthName(newMonthTo) + " " + newDayTo + ", " + newYearTo + " 12:00:00 UTC"      
          })
      
          if(isAuthenticated.value == true){
            let newEvent = {
            userid: state.user.userID,
            eventidDB : events[index].eventidDB,
            color: events[index].color, 
            title: events[index].title,
            datefrom: newYearFrom + "-" + newMonthFrom + "-" + newDayFrom,
            dateto: newYearTo + "-" + newMonthTo + "-" + newDayTo,
            datefromJson: getMonthName(newMonthFrom) + " " + newDayFrom + ", " + newYearFrom + " 12:00:00 UTC" ,
            datetoJson: getMonthName(newMonthTo) + " " + newDayTo + ", " + newYearTo + " 12:00:00 UTC"      
            }
            store.dispatch("updateEvent", newEvent);
          }
          
          events.splice(index, 1);
          calenderCore1.updateCalender();

          } 
        }
      }
    }

    return {
      toggle,
      handleShow,
      handleClick,
      handleHide,
      startDrag,
      onDrop,
      calenderCore1,
    }
  }
})
</script>

<style scoped>



.day-item {
  border-radius: 0px;
  cursor: pointer;
  border: 0px solid rgba(210, 202, 202, 0.802);
  background-color: rgba(255, 255, 255, 0.758);
  padding-top: 5px;
  color:#0b3558;
  font-size: medium;
  font-weight:300;
  text-align: center;
  user-select: none;
  
}

.buttonbottom_close{
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
}

.buttonbottom_save{
    margin-right: 0px;
    margin-bottom: 0px;
}

.go-right{
  justify-content: flex-end;
  background-color: white;
}
.square {
 height: 15px;
  width: 5px;
  background-color: #0b3558;
}

.day-grid-container{
  background-color: #ffffff;
  grid-auto-columns: 1fr;
  display: grid;
  width: 100%;
  height: 100%;
  user-select: none;
}

.SetEllipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.red-underline{
    text-decoration: underline rgb(184, 1, 1);
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
}
.item-small-top {
    user-select: none;
    display: flex;
    align-items:center;
    grid-column: 1 / span 1;
    padding-left: 0px;
    padding-right: 0px;
    font-size:x-small;
    font-weight: 500;
    height:100%;
    border-top-width: 1px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-style: solid;
    border-top-color: rgb(204, 204, 204);
    border-radius: 1px;
}

.item-small-bottom {
    user-select: none;
    display: flex;
    align-items:center;
    grid-column: 1 / span 1;
    padding-left: 0px;
    padding-right: 0px;
    font-size:x-small;
    font-weight: 500;
    height:100%;
    border-top-width: 1px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-style: solid;
    border-top-color: rgb(204, 204, 204);
    border-bottom-color: rgb(204, 204, 204);
    border-radius: 1px;
}

.item-large-top {
  display: flex;
  align-items:center;
  justify-content:left;
  float: left;
  width: 100%;
  height: 50%;
  margin-bottom: -50px;
  background-repeat: no-repeat  ;
  background-size: 25%;
  background-position: center right ;
  background-origin: content-box;
  padding-right: 4px;
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-radius: 1px;
  grid-column: 2 / span 2;       
  font-size: 11px;
  color:#ffffff;
  text-align: center;
  font-weight: 600;
  height:100%;
  width:90%;
  right:2%;
  left:2%;
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-style: solid;
  border-top-color: rgb(197, 194, 194);
  
}

.item-large-bottom {
  display: flex;
  align-items:center;
  justify-content:left;
  float: left;
  width: 100%;
  height: 50%;
  margin-bottom: -50px;
  background-repeat: no-repeat  ;
  background-size: 25%;
  background-position: center right ;
  background-origin: content-box;
  padding-right: 4px;
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-radius: 1px;
  grid-column: 2 / span 2;       
  font-size: 11px;
  color:#ffffff;
  text-align: center;
  font-weight: 600;
  height:100%;
  width:90%;
  right:2%;
  left:2%;
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-style: solid;
  border-top-color: rgb(197, 194, 194);
  border-bottom-color: rgb(197, 194, 194);
  
}

.weekday-number{
    font-weight: 800;
    font-size: 11px;
    color:#0b3558;
  
} 

.weekday-name{
    font-weight: 300;
    font-size: 11px;

}

.popoverPanel {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(66, 66, 66, 0.5);
  font-size: .875rem;
  display: none;
}

.popoverPanel[data-show] {
  display: block;
}

.popoverArrow,
.popoverArrow::before {
  position: absolute;
  width: 9px;
  height: 9px;
  background:inherit;
  
}

.popoverArrow {
  visibility: hidden;
}

.popoverArrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.color-selector{
  width:41px;
  height:41px;
  border-radius: 25px;
  margin-top:13px;
  margin-left: 10px;
  margin-right: 8px;
  margin-bottom: 10px;
}

.color-selector2{
  width:41px;
  height:41px;
  border-radius: 25px;
  margin-top:13px;
  margin-left: 10px;
  margin-right: 8px;
  margin-bottom: 10px;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width:100%;
    background-color: #FFFFFF;
    padding-left: 7px;
    padding-bottom: 15px;
    text-align: center;
   
}

.popoverPanel[data-popper-placement^='top'] > .popoverArrow {
  bottom: -5px;
}

.popoverPanel[data-popper-placement^='top'] > .popoverArrow::before {
  border-bottom: 1px solid rgba(66, 66, 66, 0.5);
  border-right: 1px solid rgba(66, 66, 66, 0.5);
}

.popoverPanel[data-popper-placement^='bottom'] > .popoverArrow {
  top: -5px;
}

.popoverPanel[data-popper-placement^='bottom'] > .popoverArrow::before {
  border-top: 1px solid rgba(66, 66, 66, 0.5);
  border-left: 1px solid rgba(66, 66, 66, 0.5);
}

.popoverPanel[data-popper-placement^='left'] > .popoverArrow {
  right: -5px;
}

.popoverPanel[data-popper-placement^='left'] > .popoverArrow::before {
  border-top: 1px solid rgba(66, 66, 66, 0.5);
  border-right: 1px solid rgba(66, 66, 66, 0.5);
}

.popoverPanel[data-popper-placement^='right'] > .popoverArrow {
  left: -5px;
}

.popoverPanel[data-popper-placement^='right'] > .popoverArrow::before {
  border-bottom: 1px solid rgba(66, 66, 66, 0.5);
  border-left: 1px solid rgba(66, 66, 66, 0.5);
}
</style>
