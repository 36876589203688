var shoolholidays = [
    {
        id: "ad7ar75wx3828xcd03", 
        title: "Weihnachtsferien",
        datefrom: "2023-01-01",
        states:["Alle Bundesländer", "Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
      {
        id: "277ar75wx3828xcd03", 
        title: "Weihnachtsferien",
        datefrom: "2023-01-02",
        states:["Alle Bundesländer", "Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "277ar75ak3828xcd03", 
        title: "Weihnachtsferien",
        datefrom: "2023-01-03",
        states:["Baden Württemberg", "Bayern", "Brandenburg", "Bremen", "Hamburg", "Hessen",  "Niedersachsen",  "Nordrhein-Westfalen", "Saarland", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "277ar75ak21s28xcd03", 
        title: "Weihnachtsferien",
        datefrom: "2023-01-04",
        states:["Baden Württemberg", "Bayern", "Bremen", "Hamburg", "Hessen",  "Niedersachsen",  "Nordrhein-Westfalen", "Saarland", "Sachsen-Anhalt", "Schleswig-Holstein"]
    },
    {
        id: "277ar75ak21sa71xcd03", 
        title: "Weihnachtsferien",
        datefrom: "2023-01-05",
        datefromJson: "January 05, 2023 12:00:00 UTC",
        states:["Baden Württemberg", "Bayern", "Bremen", "Hamburg", "Hessen",  "Niedersachsen",  "Nordrhein-Westfalen", "Sachsen-Anhalt", "Schleswig-Holstein"]
    },
    {
        id: "277ar75ak21sa71xcd03", 
        title: "Weihnachtsferien",
        datefrom: "2023-01-06",
        states:["Baden Württemberg", "Bayern", "Bremen", "Hamburg", "Hessen",  "Niedersachsen",  "Nordrhein-Westfalen", "Schleswig-Holstein"]
    },
    {
        id: "277we75ak2ssa71xcdq3", 
        title: "Weihnachtsferien",
        datefrom: "2023-01-07",
        states:["Baden Württemberg", "Bayern", "Hessen", "Schleswig-Holstein"]
    },
    {
        id: "289we757kk2ssa71xcdq3", 
        title: "Winterferien",
        datefrom: "2023-01-27",
        states:["Hamburg"]
    },
    {
        id: "199we75gfk2ssa71xcdq3", 
        title: "Winterferien",
        datefrom: "2023-01-30",
        states:["Berlin", "Brandenburg", "Bremen", "Niedersachsen"]
    },
    {
        id: "199ddsk23sa4xcdq3", 
        title: "Winterferien",
        datefrom: "2023-01-31",
        states:["Berlin", "Brandenburg", "Bremen", "Niedersachsen"]
    },
    {
        id: "sw9ddsk23345xcdq3", 
        title: "Winterferien",
        datefrom: "2023-02-01",
        states:["Berlin", "Brandenburg"]
    },
    {
        id: "sw9ddsk2xsq5xcdq3", 
        title: "Winterferien",
        datefrom: "2023-02-02",
        states:["Berlin", "Brandenburg"]
    },
    {
        id: "sw98dsr2xsqdxcds3", 
        title: "Winterferien",
        datefrom: "2023-02-03",
        states:["Berlin", "Brandenburg"]
    },
    {
        id: "sw98dsr2xsqahads3", 
        title: "Winterferien",
        datefrom: "2023-02-04",
        states:["Berlin"]
    },
    {
        id: "3428dsr2xaswhads3", 
        title: "Winterferien",
        datefrom: "2023-02-06",
        states:["Mecklenburg-Vorpommern", "Sachsen-Anhalt"]
    },
    {
        id: "3428dsr2xawwxxds3", 
        title: "Winterferien",
        datefrom: "2023-02-07",
        states:["Mecklenburg-Vorpommern", "Sachsen-Anhalt"]
    },
    {
        id: "3428dsrrrawwxxds3", 
        title: "Winterferien",
        datefrom: "2023-02-08",
        states:["Mecklenburg-Vorpommern", "Sachsen-Anhalt"]
    },
    {
        id: "3428dsr2erwwsrds3", 
        title: "Winterferien",
        datefrom: "2023-02-09",
        states:["Mecklenburg-Vorpommern", "Sachsen-Anhalt"]
    },
    {
        id: "xy28dsr2xawwxyds3", 
        title: "Winterferien",
        datefrom: "2023-02-10",
        states:["Mecklenburg-Vorpommern", "Sachsen-Anhalt"]
    },
    {
        id: "3428dsr2xswwxddsg", 
        title: "Winterferien",
        datefrom: "2023-02-11",
        states:["Mecklenburg-Vorpommern", "Sachsen-Anhalt"]
    },
    {
        id: "3428dsr2xswwxasd1", 
        title: "Winterferien",
        datefrom: "2023-02-12",
        states:["Mecklenburg-Vorpommern"]
    },
    {
        id: "3428dsr2xswwxasd23", 
        title: "Winterferien",
        datefrom: "2023-02-13",
        states:["Mecklenburg-Vorpommern", "Sachsen", "Thüringen"]
    },
    {
        id: "3428dsr2x89wxasd25", 
        title: "Winterferien",
        datefrom: "2023-02-14",
        states:["Mecklenburg-Vorpommern", "Sachsen", "Thüringen"]
    },
    {
        id: "3428dsr2x899xasd28", 
        title: "Winterferien",
        datefrom: "2023-02-15",
        states:["Mecklenburg-Vorpommern", "Sachsen", "Thüringen"]
    },
    {
        id: "342rdsr2x89wxasd28r", 
        title: "Winterferien",
        datefrom: "2023-02-16",
        states:["Mecklenburg-Vorpommern", "Sachsen", "Thüringen"]
    },
    {
        id: "342rdsr2x89wxasd59tk", 
        title: "Winterferien",
        datefrom: "2023-02-17",
        states:["Mecklenburg-Vorpommern", "Sachsen", "Thüringen"]
    },
    {
        id: "1242rdxr2x89wxasd78te", 
        title: "Winterferien",
        datefrom: "2023-02-18",
        states:["Mecklenburg-Vorpommern", "Sachsen"]
    },
    {
        id: "1242rdxr2x89wxasd78te001", 
        title: "Winterferien",
        datefrom: "2023-02-19",
        states:["Sachsen"]
    },
    {
        id: "1242rdxr2x89wxasd78te002", 
        title: "Winterferien",
        datefrom: "2023-02-20",
        states:["Bayern", "Saarland", "Sachsen"]
    },
    {
        id: "1242rdxr2x89wxasd78te003", 
        title: "Winterferien",
        datefrom: "2023-02-21",
        states:["Bayern", "Saarland", "Sachsen"]
    },
    {
        id: "1242rdxr2x89wxasd78te004", 
        title: "Winterferien",
        datefrom: "2023-02-22",
        states:["Bayern", "Saarland", "Sachsen"]
    },
    {
        id: "1242rdxr2x89wxasd78te005", 
        title: "Winterferien",
        datefrom: "2023-02-23",
        states:["Bayern", "Saarland", "Sachsen"]
    },
    {
        id: "1242rdxr2x89wxasd78te006", 
        title: "Winterferien",
        datefrom: "2023-02-24",
        states:["Bayern", "Saarland", "Sachsen"]
    },
    {
        id: "1242rdxr2x89wxasd78te008", 
        title: "Osterferien",
        datefrom: "2023-03-27",
        states:["Bremen","Niedersachsen"]
    },
    {
        id: "1242rdxr2x89wxasd78te009", 
        title: "Osterferien",
        datefrom: "2023-03-28",
        states:["Bremen","Niedersachsen"]
    },
    {
        id: "1242rdxr2x89wxasd78te010", 
        title: "Osterferien",
        datefrom: "2023-03-29",
        states:["Bremen","Niedersachsen"]
    },
    {
        id: "1242rdxr2x89wxasd78te011", 
        title: "Osterferien",
        datefrom: "2023-03-30",
        states:["Bremen","Niedersachsen"]
    },
    {
        id: "1242rdxr2x89wxasd78te012", 
        title: "Osterferien",
        datefrom: "2023-03-31",
        states:["Bremen","Niedersachsen"]
    },
    {
        id: "1242rdxr2x89wxasd78te013", 
        title: "Osterferien",
        datefrom: "2023-04-01",
        states:["Bremen","Niedersachsen"]
    },
     {
        id: "1242rdxr2x89wxasd78te014", 
        title: "Osterferien",
        datefrom: "2023-04-02",
        states:["Bremen","Niedersachsen"]
    },
    {
        id: "1242rdxr2x89wx3xd78text7", 
        title: "Osterferien",
        datefrom: "2023-04-03",
        states:["Bayern", "Berlin", "Brandenburg", "Bremen", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen",  "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x89wtsxd78tex15", 
        title: "Osterferien",
        datefrom: "2023-04-04",
        states:["Bayern", "Berlin", "Brandenburg", "Bremen", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen",  "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen-Anhalt",  "Thüringen"]
    },
    {
        id: "1242rdxr2x89wtsxd78tex16", 
        title: "Osterferien",
        datefrom: "2023-04-05",
        states:["Bayern", "Berlin", "Brandenburg", "Bremen", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen",  "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen-Anhalt",  "Thüringen"]
    },
    {
        id: "1242rdxr2x89wtsxd78tex17", 
        title: "Osterferien",
        datefrom: "2023-04-06",
        states:["Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen",  "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen-Anhalt", "Schleswig-Holstein",  "Thüringen"]
    },
    {
        id: "1242rdxr2x89wtsxd78tex18", 
        title: "Osterferien",
        datefrom: "2023-04-07",
        states:["Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen",  "Nordrhein-Westfalen",  "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein",  "Thüringen"]
    },
    {
        id: "1242rdxr2x89wtsxd78tex19", 
        title: "Osterferien",
        datefrom: "2023-04-08",
        states:["Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen",  "Nordrhein-Westfalen", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein",  "Thüringen"]
    },
    {
        id: "1242rdxr2x89wtsxd78tex20", 
        title: "Osterferien",
        datefrom: "2023-04-09",
        states:["Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen",  "Nordrhein-Westfalen", "Saarland", "Sachsen", "Schleswig-Holstein",  "Thüringen"]
    },
    {
        id: "1242rdxr2x89wtsxd78tex21", 
        title: "Osterferien",
        datefrom: "2023-04-10",
        states:["Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen",  "Nordrhein-Westfalen", "Saarland", "Sachsen", "Schleswig-Holstein",  "Thüringen"]
    },
    {
        id: "1242rdxr2x89wtsxd78tex22", 
        title: "Osterferien",
        datefrom: "2023-04-11",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen",  "Nordrhein-Westfalen", "Saarland", "Sachsen", "Schleswig-Holstein",  "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex23", 
        title: "Osterferien",
        datefrom: "2023-04-12",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Nordrhein-Westfalen", "Saarland", "Sachsen", "Schleswig-Holstein",  "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex24", 
        title: "Osterferien",
        datefrom: "2023-04-13",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Hamburg", "Hessen", "Nordrhein-Westfalen", "Sachsen", "Schleswig-Holstein",  "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex25", 
        title: "Osterferien",
        datefrom: "2023-04-14",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Hamburg", "Hessen", "Nordrhein-Westfalen", "Sachsen", "Schleswig-Holstein",  "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex26", 
        title: "Osterferien",
        datefrom: "2023-04-15",
        states:["Baden Württemberg", "Bayern", "Hamburg", "Hessen", "Nordrhein-Westfalen", "Sachsen", "Schleswig-Holstein",  "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex27", 
        title: "Osterferien",
        datefrom: "2023-04-16",
        states:["Hamburg", "Hessen", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex28", 
        title: "Osterferien",
        datefrom: "2023-04-17",
        states:["Hamburg", "Hessen", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex29", 
        title: "Osterferien",
        datefrom: "2023-04-18",
        states:["Hessen", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex30", 
        title: "Osterferien",
        datefrom: "2023-04-19",
        states:["Hessen", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex31", 
        title: "Osterferien",
        datefrom: "2023-04-20",
        states:["Hessen", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex32", 
        title: "Osterferien",
        datefrom: "2023-04-21",
        states:["Hessen", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex32", 
        title: "Osterferien",
        datefrom: "2023-04-22",
        states:["Hessen", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex33", 
        title: "Pfingstferien",
        datefrom: "2023-05-15",
        states:["Hamburg",  "Sachsen-Anhalt"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex34", 
        title: "Pfingstferien",
        datefrom: "2023-05-16",
        states:["Hamburg",  "Sachsen-Anhalt"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex35", 
        title: "Pfingstferien",
        datefrom: "2023-05-17",
        states:["Hamburg", "Sachsen-Anhalt"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex36", 
        title: "Pfingstferien",
        datefrom: "2023-05-18",
        states:["Hamburg", "Sachsen-Anhalt"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex37", 
        title: "Pfingstferien",
        datefrom: "2023-05-19",
        states:["Berlin", "Brandenburg", "Bremen", "Hamburg", "Mecklenburg-Vorpommern", "Niedersachsen",  "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex38", 
        title: "Pfingstferien",
        datefrom: "2023-05-20",
        states:["Berlin", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex39", 
        title: "Pfingstferien",
        datefrom: "2023-05-26",
        states:["Mecklenburg-Vorpommern"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex40", 
        title: "Pfingstferien",
        datefrom: "2023-05-27",
        states:["Mecklenburg-Vorpommern"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex41", 
        title: "Pfingstferien",
        datefrom: "2023-05-28",
        states:["Mecklenburg-Vorpommern"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex42", 
        title: "Pfingstferien",
        datefrom: "2023-05-29",
        states:["Mecklenburg-Vorpommern"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex43", 
        title: "Pfingstferien",
        datefrom: "2023-05-30",
        states:["Baden Württemberg", "Bayern", "Berlin", "Bremen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex44", 
        title: "Pfingstferien",
        datefrom: "2023-05-31",
        states:["Baden Württemberg", "Bayern", "Rheinland-Pfalz", "Saarland"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex45", 
        title: "Pfingstferien",
        datefrom: "2023-06-01",
        states:["Baden Württemberg", "Bayern", "Rheinland-Pfalz", "Saarland"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex46", 
        title: "Pfingstferien",
        datefrom: "2023-06-02",
        states:["Baden Württemberg", "Bayern", "Rheinland-Pfalz", "Saarland"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex47", 
        title: "Pfingstferien",
        datefrom: "2023-06-03",
        states:["Baden Württemberg", "Bayern", "Rheinland-Pfalz"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex48", 
        title: "Pfingstferien",
        datefrom: "2023-06-04",
        states:["Baden Württemberg", "Bayern", "Rheinland-Pfalz"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex49", 
        title: "Pfingstferien",
        datefrom: "2023-06-05",
        states:["Baden Württemberg", "Bayern", "Rheinland-Pfalz"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex50", 
        title: "Pfingstferien",
        datefrom: "2023-06-06",
        states:["Baden Württemberg", "Bayern", "Rheinland-Pfalz"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex51", 
        title: "Pfingstferien",
        datefrom: "2023-06-07",
        states:["Baden Württemberg", "Bayern", "Rheinland-Pfalz"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex52", 
        title: "Pfingstferien",
        datefrom: "2023-06-08",
        states:["Baden Württemberg", "Bayern"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex53", 
        title: "Pfingstferien",
        datefrom: "2023-06-09",
        states:["Baden Württemberg", "Bayern"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex54", 
        title: "Sommerferien",
        datefrom: "2023-06-22",
        states:["Nordrhein-Westfalen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex55", 
        title: "Sommerferien",
        datefrom: "2023-06-23",
        states:["Nordrhein-Westfalen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex56", 
        title: "Sommerferien",
        datefrom: "2023-06-24",
        states:["Nordrhein-Westfalen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex57", 
        title: "Sommerferien",
        datefrom: "2023-06-25",
        states:["Nordrhein-Westfalen"]
    },
        {
        id: "1242rdxr2x39wtsxd78tex58", 
        title: "Sommerferien",
        datefrom: "2023-06-26",
        states:["Nordrhein-Westfalen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex59", 
        title: "Sommerferien",
        datefrom: "2023-06-27",
        states:["Nordrhein-Westfalen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex60", 
        title: "Sommerferien",
        datefrom: "2023-06-28",
        states:["Nordrhein-Westfalen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex61", 
        title: "Sommerferien",
        datefrom: "2023-06-29",
        states:["Nordrhein-Westfalen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex62", 
        title: "Sommerferien",
        datefrom: "2023-06-29",
        states:["Nordrhein-Westfalen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex63", 
        title: "Sommerferien",
        datefrom: "2023-06-30",
        states:["Nordrhein-Westfalen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex64", 
        title: "Sommerferien",
        datefrom: "2023-07-01",
        states:["Nordrhein-Westfalen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex65", 
        title: "Sommerferien",
        datefrom: "2023-07-02",
        states:["Nordrhein-Westfalen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex66", 
        title: "Sommerferien",
        datefrom: "2023-07-03",
        states:["Nordrhein-Westfalen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex67", 
        title: "Sommerferien",
        datefrom: "2023-07-04",
        states:["Nordrhein-Westfalen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex68", 
        title: "Sommerferien",
        datefrom: "2023-07-05",
        states:["Nordrhein-Westfalen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex69", 
        title: "Sommerferien",
        datefrom: "2023-07-06",
        states:["Bremen", "Niedersachsen","Nordrhein-Westfalen", "Sachsen-Anhalt"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex70", 
        title: "Sommerferien",
        datefrom: "2023-07-07",
        states:["Bremen", "Niedersachsen", "Nordrhein-Westfalen", "Sachsen-Anhalt"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex71", 
        title: "Sommerferien",
        datefrom: "2023-07-08",
        states:["Bremen", "Niedersachsen", "Nordrhein-Westfalen", "Sachsen-Anhalt"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex72", 
        title: "Sommerferien",
        datefrom: "2023-07-09",
        states:["Bremen", "Niedersachsen", "Nordrhein-Westfalen", "Sachsen-Anhalt"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex73", 
        title: "Sommerferien",
        datefrom: "2023-07-10",
        states:["Bremen", "Niedersachsen", "Nordrhein-Westfalen", "Sachsen", "Sachsen-Anhalt", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex74", 
        title: "Sommerferien",
        datefrom: "2023-07-11",
        states:["Bremen", "Niedersachsen", "Nordrhein-Westfalen", "Sachsen", "Sachsen-Anhalt", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex75", 
        title: "Sommerferien",
        datefrom: "2023-07-11",
        states:["Bremen", "Niedersachsen", "Nordrhein-Westfalen", "Sachsen", "Sachsen-Anhalt", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex76", 
        title: "Sommerferien",
        datefrom: "2023-07-12",
        states:["Bremen", "Niedersachsen", "Nordrhein-Westfalen", "Sachsen", "Sachsen-Anhalt", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex77", 
        title: "Sommerferien",
        datefrom: "2023-07-13",
        states:["Berlin", "Brandenburg", "Bremen", "Hamburg", "Niedersachsen", "Nordrhein-Westfalen", "Sachsen", "Sachsen-Anhalt", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex78", 
        title: "Sommerferien",
        datefrom: "2023-07-14",
        states:["Berlin", "Brandenburg", "Bremen", "Hamburg", "Niedersachsen", "Nordrhein-Westfalen", "Sachsen", "Sachsen-Anhalt", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex79", 
        title: "Sommerferien",
        datefrom: "2023-07-15",
        states:["Berlin", "Brandenburg", "Bremen", "Hamburg", "Niedersachsen", "Nordrhein-Westfalen", "Sachsen", "Sachsen-Anhalt", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex80", 
        title: "Sommerferien",
        datefrom: "2023-07-16",
        states:["Berlin", "Brandenburg", "Bremen", "Hamburg", "Niedersachsen", "Nordrhein-Westfalen", "Sachsen", "Sachsen-Anhalt", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex81", 
        title: "Sommerferien",
        datefrom: "2023-07-17",
        states:["Berlin", "Brandenburg", "Bremen", "Hamburg", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex82", 
        title: "Sommerferien",
        datefrom: "2023-07-18",
        states:["Berlin", "Brandenburg", "Bremen", "Hamburg", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex83", 
        title: "Sommerferien",
        datefrom: "2023-07-18",
        states:["Berlin", "Brandenburg", "Bremen", "Hamburg", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex84", 
        title: "Sommerferien",
        datefrom: "2023-07-19",
        states:["Berlin", "Brandenburg", "Bremen", "Hamburg", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex85", 
        title: "Sommerferien",
        datefrom: "2023-07-20",
        states:["Berlin", "Brandenburg", "Bremen", "Hamburg", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex86", 
        title: "Sommerferien",
        datefrom: "2023-07-21",
        states:["Berlin", "Brandenburg", "Bremen", "Hamburg", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex87", 
        title: "Sommerferien",
        datefrom: "2023-07-22",
        states:["Berlin", "Brandenburg", "Bremen", "Hamburg", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex88", 
        title: "Sommerferien",
        datefrom: "2023-07-23",
        states:["Berlin", "Brandenburg", "Bremen", "Hamburg", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex86", 
        title: "Sommerferien",
        datefrom: "2023-07-24",
        states:["Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex87", 
        title: "Sommerferien",
        datefrom: "2023-07-25",
        states:["Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex88", 
        title: "Sommerferien",
        datefrom: "2023-07-25",
        states:["Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex89", 
        title: "Sommerferien",
        datefrom: "2023-07-26",
        states:["Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex90", 
        title: "Sommerferien",
        datefrom: "2023-07-27",
        states:["Baden Württemberg", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex91", 
        title: "Sommerferien",
        datefrom: "2023-07-28",
        states:["Baden Württemberg", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex92", 
        title: "Sommerferien",
        datefrom: "2023-07-29",
        states:["Baden Württemberg", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
     {
        id: "1242rdxr2x39wtsxd78tex93", 
        title: "Sommerferien",
        datefrom: "2023-07-30",
        states:["Baden Württemberg", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex94", 
        title: "Sommerferien",
        datefrom: "2023-07-31",
        states:["Alle Bundesländer", "Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex95", 
        title: "Sommerferien",
        datefrom: "2023-08-01",
        states:["Alle Bundesländer", "Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex96", 
        title: "Sommerferien",
        datefrom: "2023-08-02",
        states:["Alle Bundesländer", "Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex97", 
        title: "Sommerferien",
        datefrom: "2023-08-03",
        states:["Alle Bundesländer", "Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex98", 
        title: "Sommerferien",
        datefrom: "2023-08-04",
        states:["Alle Bundesländer", "Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex96", 
        title: "Sommerferien",
        datefrom: "2023-08-05",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex97", 
        title: "Sommerferien",
        datefrom: "2023-08-06",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
      {
        id: "1242rdxr2x39wtsxd78tex98", 
        title: "Sommerferien",
        datefrom: "2023-08-07",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex99", 
        title: "Sommerferien",
        datefrom: "2023-08-08",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex100", 
        title: "Sommerferien",
        datefrom: "2023-08-09",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex101", 
        title: "Sommerferien",
        datefrom: "2023-08-10",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex102", 
        title: "Sommerferien",
        datefrom: "2023-08-11",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex103", 
        title: "Sommerferien",
        datefrom: "2023-08-12",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex104", 
        title: "Sommerferien",
        datefrom: "2023-08-13",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex105", 
        title: "Sommerferien",
        datefrom: "2023-08-14",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
        {
        id: "1242rdxr2x39wtsxd78tex106", 
        title: "Sommerferien",
        datefrom: "2023-08-15",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex107", 
        title: "Sommerferien",
        datefrom: "2023-08-16",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex108", 
        title: "Sommerferien",
        datefrom: "2023-08-17",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Rheinland-Pfalz", "Saarland", "Sachsen", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex109", 
        title: "Sommerferien",
        datefrom: "2023-08-18",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Rheinland-Pfalz", "Saarland", "Sachsen", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex110", 
        title: "Sommerferien",
        datefrom: "2023-08-19",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Rheinland-Pfalz", "Saarland", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex111", 
        title: "Sommerferien",
        datefrom: "2023-08-20",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Rheinland-Pfalz", "Saarland", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex112", 
        title: "Sommerferien",
        datefrom: "2023-08-21",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Rheinland-Pfalz", "Saarland", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex113", 
        title: "Sommerferien",
        datefrom: "2023-08-22",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Rheinland-Pfalz", "Saarland", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex114", 
        title: "Sommerferien",
        datefrom: "2023-08-23",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Rheinland-Pfalz", "Saarland", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex115", 
        title: "Sommerferien",
        datefrom: "2023-08-24",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Hessen", "Mecklenburg-Vorpommern", "Rheinland-Pfalz", "Saarland", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex116", 
        title: "Sommerferien",
        datefrom: "2023-08-25",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Hessen", "Mecklenburg-Vorpommern", "Rheinland-Pfalz", "Saarland", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex117", 
        title: "Sommerferien",
        datefrom: "2023-08-26",
        states:["Baden Württemberg", "Bayern", "Brandenburg", "Hessen", "Mecklenburg-Vorpommern", "Rheinland-Pfalz", "Saarland", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex118", 
        title: "Sommerferien",
        datefrom: "2023-08-27",
        states:["Baden Württemberg", "Bayern", "Hessen", "Rheinland-Pfalz", "Saarland"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex119", 
        title: "Sommerferien",
        datefrom: "2023-08-28",
        states:["Baden Württemberg", "Bayern", "Hessen", "Rheinland-Pfalz", "Saarland"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex120", 
        title: "Sommerferien",
        datefrom: "2023-08-29",
        states:["Baden Württemberg", "Bayern", "Hessen", "Rheinland-Pfalz", "Saarland"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex121", 
        title: "Sommerferien",
        datefrom: "2023-08-30",
        states:["Baden Württemberg", "Bayern", "Hessen", "Rheinland-Pfalz", "Saarland"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex122", 
        title: "Sommerferien",
        datefrom: "2023-08-31",
        states:["Baden Württemberg", "Bayern", "Hessen", "Rheinland-Pfalz", "Saarland"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex123", 
        title: "Sommerferien",
        datefrom: "2023-09-01",
        states:["Baden Württemberg", "Bayern", "Hessen", "Rheinland-Pfalz", "Saarland"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex124", 
        title: "Sommerferien",
        datefrom: "2023-09-02",
        states:["Baden Württemberg", "Bayern"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex125", 
        title: "Sommerferien",
        datefrom: "2023-09-03",
        states:["Baden Württemberg", "Bayern"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex126", 
        title: "Sommerferien",
        datefrom: "2023-09-04",
        states:["Baden Württemberg", "Bayern"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex127", 
        title: "Sommerferien",
        datefrom: "2023-09-05",
        states:["Baden Württemberg", "Bayern"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex128", 
        title: "Sommerferien",
        datefrom: "2023-09-06",
        states:["Baden Württemberg", "Bayern"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex129", 
        title: "Sommerferien",
        datefrom: "2023-09-07",
        states:["Baden Württemberg", "Bayern"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex130", 
        title: "Sommerferien",
        datefrom: "2023-09-08",
        states:["Baden Württemberg", "Bayern"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex131", 
        title: "Sommerferien",
        datefrom: "2023-09-09",
        states:["Baden Württemberg", "Bayern"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex132", 
        title: "Sommerferien",
        datefrom: "2023-09-10",
        states:["Bayern"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex133", 
        title: "Sommerferien",
        datefrom: "2023-09-11",
        states:["Bayern"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex134", 
        title: "Herbstferien",
        datefrom: "2023-10-02",
        states:["Berlin", "Brandenburg", "Bremen", "Hamburg", "Niedersachsen", "Nordrhein-Westfalen", "Sachsen", "Sachsen-Anhalt", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1345", 
        title: "Herbstferien",
        datefrom: "2023-10-03",
        states:["Nordrhein-Westfalen", "Sachsen", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1346", 
        title: "Herbstferien",
        datefrom: "2023-10-04",
        states:["Nordrhein-Westfalen", "Sachsen", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1347", 
        title: "Herbstferien",
        datefrom: "2023-10-05",
        states:["Nordrhein-Westfalen", "Sachsen", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1348", 
        title: "Herbstferien",
        datefrom: "2023-10-06",
        states:["Nordrhein-Westfalen", "Sachsen", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1349", 
        title: "Herbstferien",
        datefrom: "2023-10-07",
        states:["Nordrhein-Westfalen", "Sachsen", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1350", 
        title: "Herbstferien",
        datefrom: "2023-10-08",
        states:["Nordrhein-Westfalen", "Sachsen", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1351", 
        title: "Herbstferien",
        datefrom: "2023-10-09",
        states:["Mecklenburg-Vorpommern", "Nordrhein-Westfalen", "Sachsen", "Thüringen"]
    },
      {
        id: "1242rdxr2x39wtsxd78tex1352", 
        title: "Herbstferien",
        datefrom: "2023-10-10",
        states:["Mecklenburg-Vorpommern","Nordrhein-Westfalen", "Sachsen", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1353", 
        title: "Herbstferien",
        datefrom: "2023-10-11",
        states:["Mecklenburg-Vorpommern","Nordrhein-Westfalen", "Sachsen", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1354", 
        title: "Herbstferien",
        datefrom: "2023-10-12",
        states:["Mecklenburg-Vorpommern","Nordrhein-Westfalen", "Sachsen", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1355", 
        title: "Herbstferien",
        datefrom: "2023-10-13",
        states:["Mecklenburg-Vorpommern","Nordrhein-Westfalen", "Sachsen", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1356", 
        title: "Herbstferien",
        datefrom: "2023-10-14",
        states:["Mecklenburg-Vorpommern","Nordrhein-Westfalen", "Sachsen", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1357", 
        title: "Herbstferien",
        datefrom: "2023-10-16",
        states:["Bremen", "Hamburg", "Niedersachsen", "Rheinland-Pfalz", "Sachsen-Anhalt", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1358", 
        title: "Herbstferien",
        datefrom: "2023-10-17",
        states:["Bremen", "Hamburg", "Niedersachsen", "Rheinland-Pfalz", "Sachsen-Anhalt", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1359", 
        title: "Herbstferien",
        datefrom: "2023-10-18",
        states:["Bremen", "Hamburg", "Niedersachsen", "Rheinland-Pfalz", "Sachsen-Anhalt", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1360", 
        title: "Herbstferien",
        datefrom: "2023-10-19",
        states:["Bremen", "Hamburg", "Niedersachsen", "Rheinland-Pfalz", "Sachsen-Anhalt", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1361", 
        title: "Herbstferien",
        datefrom: "2023-10-20",
        states:["Bremen", "Hamburg", "Niedersachsen", "Rheinland-Pfalz", "Sachsen-Anhalt", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1362", 
        title: "Herbstferien",
        datefrom: "2023-10-21",
        states:["Bremen", "Hamburg", "Niedersachsen", "Rheinland-Pfalz", "Sachsen-Anhalt", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1363", 
        title: "Herbstferien",
        datefrom: "2023-10-22",
        states:["Bremen", "Hamburg", "Niedersachsen", "Rheinland-Pfalz", "Sachsen-Anhalt", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1364", 
        title: "Herbstferien",
        datefrom: "2023-10-23",
        states:["Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Niedersachsen", "Rheinland-Pfalz",  "Saarland", "Sachsen-Anhalt", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1366", 
        title: "Herbstferien",
        datefrom: "2023-10-24",
        states:["Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Niedersachsen", "Rheinland-Pfalz",  "Saarland", "Sachsen-Anhalt", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1367", 
        title: "Herbstferien",
        datefrom: "2023-10-25",
        states:["Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Niedersachsen", "Rheinland-Pfalz",  "Saarland", "Sachsen-Anhalt", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1368", 
        title: "Herbstferien",
        datefrom: "2023-10-26",
        states:["Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Niedersachsen", "Rheinland-Pfalz",  "Saarland", "Sachsen-Anhalt", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1369", 
        title: "Herbstferien",
        datefrom: "2023-10-27",
        states:["Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Niedersachsen", "Rheinland-Pfalz",  "Saarland", "Sachsen-Anhalt", "Schleswig-Holstein"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1370", 
        title: "Herbstferien",
        datefrom: "2023-10-28",
        states:["Berlin", "Brandenburg", "Bremen",  "Hessen", "Niedersachsen", "Saarland", "Sachsen-Anhalt"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1371", 
        title: "Herbstferien",
        datefrom: "2023-10-29",
        states:["Berlin", "Brandenburg", "Bremen",  "Niedersachsen", "Saarland", "Sachsen-Anhalt"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1372", 
        title: "Herbstferien",
        datefrom: "2023-10-30",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen","Mecklenburg-Vorpommern", "Niedersachsen", "Saarland", "Sachsen-Anhalt"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1373", 
        title: "Herbstferien",
        datefrom: "2023-10-31",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Saarland"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1374", 
        title: "Herbstferien",
        datefrom: "2023-11-01",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Mecklenburg-Vorpommern", "Saarland"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1375", 
        title: "Herbstferien",
        datefrom: "2023-11-02",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Saarland"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1376", 
        title: "Herbstferien",
        datefrom: "2023-11-03",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Saarland"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1378", 
        title: "Herbstferien",
        datefrom: "2023-11-04",
        states:["Berlin", "Brandenburg"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1379", 
        title: "Herbstferien",
        datefrom: "2023-11-22",
        states:["Bayern"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1380", 
        title: "Weihnachtsferien",
        datefrom: "2023-12-21",
        states:["Mecklenburg-Vorpommern", "Nordrhein-Westfalen", "Saarland", "Sachsen-Anhalt"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1381", 
        title: "Weihnachtsferien",
        datefrom: "2023-12-22",
        states:["Mecklenburg-Vorpommern", "Nordrhein-Westfalen", "Saarland", "Sachsen-Anhalt", "Hamburg", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1382", 
        title: "Weihnachtsferien",
        datefrom: "2023-12-23",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Mecklenburg-Vorpommern", "Nordrhein-Westfalen", "Saarland",  "Sachsen-Anhalt",  "Sachsen", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1383", 
        title: "Weihnachtsferien",
        datefrom: "2023-12-24",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Mecklenburg-Vorpommern", "Nordrhein-Westfalen", "Saarland", "Sachsen-Anhalt",  "Sachsen", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1384", 
        title: "Weihnachtsferien",
        datefrom: "2023-12-25",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Mecklenburg-Vorpommern", "Nordrhein-Westfalen", "Saarland", "Sachsen-Anhalt",  "Sachsen", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1385", 
        title: "Weihnachtsferien",
        datefrom: "2023-12-26",
        states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Mecklenburg-Vorpommern", "Nordrhein-Westfalen", "Saarland", "Sachsen-Anhalt",  "Sachsen", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1386", 
        title: "Weihnachtsferien",
        datefrom: "2023-12-27",
        states:["Alle Bundesländer", "Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen-Anhalt",  "Sachsen", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1387", 
        title: "Weihnachtsferien",
        datefrom: "2023-12-28",
        states:["Alle Bundesländer", "Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen-Anhalt",  "Sachsen", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1387", 
        title: "Weihnachtsferien",
        datefrom: "2023-12-29",
        states:["Alle Bundesländer", "Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen-Anhalt",  "Sachsen", "Schleswig-Holstein", "Thüringen"]
    },
    {
        id: "1242rdxr2x39wtsxd78tex1388", 
        title: "Weihnachtsferien",
        datefrom: "2023-12-30",
        states:["Alle Bundesländer", "Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen-Anhalt",  "Sachsen", "Schleswig-Holstein", "Thüringen"]
    },
      {
        id: "1242rdxr2x39wtsxd78tex1389", 
        title: "Weihnachtsferien",
        datefrom: "2023-12-31",
        states:["Alle Bundesländer", "Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen-Anhalt",  "Sachsen", "Schleswig-Holstein", "Thüringen"]
    },
     
    //states:["Baden Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]
  
  ]
  
  export default {
  
      state(){
          return{
              items: [],
          }
      },
      actions:{
          getShoolholidays({commit}){
              commit("setShoolholidays",shoolholidays);
              
          }
      },
      mutations:{
          setShoolholidays(state, shoolholidays){
              state.items = shoolholidays;
          }
      }
  
  }
  
  