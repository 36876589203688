import { useStore } from "vuex";
import { computed, ref } from "vue";

export default function calenderCore() {
    const store = useStore();
    const { state } = store;
    
    const items = ref(computed(() => state.calender.items));
    const events = ref(computed(() => state.events.items));
    const publicholidays = ref(computed(() => state.publicholidays.items));
    const shoolholidays = ref(computed(() => state.schoolholidays.items));

    function collectSpecialHolidays(){
        for (let index = 0; index < items.value.length; ++index){
            for (let index2 = 0; index2 < publicholidays.value.length; ++index2){
                if((items.value[index].id == publicholidays.value[index2].datefrom)){
                    items.value[index].publicHolidays = publicholidays.value[index2];
                }
            }
            for (let index3 = 0; index3 < shoolholidays.value.length; ++index3){
                if((items.value[index].id == shoolholidays.value[index3].datefrom)){
                    items.value[index].shoolholidays = shoolholidays.value[index3];
                }
            }
        }
    }

    function updateCalender(){
           
        for (let index = 0; index < items.value.length; ++index){
            this.updateSingleDay(items.value[index]);
        }
        state.settings.FixedWithMousUp = false
    }

    /*
    function getSpecialEvent(dayToCheck, specialevents, backcolor){
        for (let index = 0; index < specialevents.value.length; ++index){
            if((dayToCheck.id == specialevents.value[index].datefrom))
            {
                if(specialevents.value[index].states.includes('Alle Bundesländer')){
                    dayToCheck.backColorWeekDay =  backcolor ;
                    if(dayToCheck.dayHasEvent == false){
                        dayToCheck.backColor =  backcolor ;
                        dayToCheck.eventText = specialevents.value[index].title;  
                    }
                    dayToCheck.showTooltip = true;  
                    dayToCheck.PublicHolidayName = specialevents.value[index].title;  
                } 
                else if(specialevents.value[index].states.includes(state.settings.bundesLaender)){
                    dayToCheck.backColorWeekDay =  backcolor ;
                    if(dayToCheck.dayHasEvent == false){
                        dayToCheck.backColor =  backcolor ;
                        dayToCheck.eventText = specialevents.value[index].title;  
                    }
                    dayToCheck.showTooltip = true;  
                    dayToCheck.PublicHolidayName = specialevents.value[index].title;  
                }            
            }
        }
    }
    */

    /*
    function getShoolHolidaysAlleBundeslaender(specialevents){
        for (let index = 0; index < specialevents.value.length; ++index){
            if( specialevents.value[index].states.includes('Baden Württemberg')&&
                specialevents.value[index].states.includes('Bayern')&&
                specialevents.value[index].states.includes('Berlin')&&
                specialevents.value[index].states.includes('Brandenburg')&&
                specialevents.value[index].states.includes('Bremen')&&
                specialevents.value[index].states.includes('Hamburg')&&
                specialevents.value[index].states.includes('Hessen')&&
                specialevents.value[index].states.includes('Mecklenburg-Vorpommern')&&
                specialevents.value[index].states.includes('Niedersachsen')&&
                specialevents.value[index].states.includes('Nordrhein-Westfalen')&&
                specialevents.value[index].states.includes('Rheinland-Pfalz')&&
                specialevents.value[index].states.includes('Saarland')&&
                specialevents.value[index].states.includes('Sachsen-Anhalt')&&
                specialevents.value[index].states.includes('Sachsen')&&
                specialevents.value[index].states.includes('Schleswig-Holstein')&&
                specialevents.value[index].states.includes('Thüringen')
                
                
                ){
                
            } 
        }
    }
    */
 

    function updateSingleDay(day){
        
        var dayHasEvent = false;     
        day.showTooltip = false;   
        day.PublicHolidayName = "";

        if(day.weekday == "So" || day.weekday == "Sa"){
            day.backColorWeekDay = state.settings.BackColorWeekend;
        }
        else{
            day.backColorWeekDay = state.settings.BackColorNormalDay;              
        }

        

        for (let index = 0; index < events.value.length; ++index) {
            
            if((day.id >= this.events.value[index].datefrom && day.id <= this.events.value[index].dateto))
            {
                if(day.weekday == "So" || day.weekday == "Sa"){
                    day.backColorWeekDay = state.settings.BackColorWeekend;
                }
                else{
                    day.backColorWeekDay = this.events.value[index].color;               
               }
                //day.backColorWeekDay = this.events.value[index].color;
                day.backColor = this.events.value[index].color;
                day.eventText = this.events.value[index].title;
                dayHasEvent = true;
                day.dayHasEvent = true;
                day.showTooltip = true;
                if(day.id == this.events.value[index].datefrom || day.id == this.events.value[index].dateto){
                    if(state.settings.ctrlPressed == true)
                    {
                        day.cursor = "grab";
                        day.draggable = true;
                    }
                    else
                    {
                        day.cursor = "ns-resize";
                        day.draggable = false;
                    }
                    
                }
                else{
                    day.cursor = "grab";
                    day.draggable = true;
                }
            }
        }
        if (dayHasEvent == false  ){
            day.draggable = false;
            day.cursor = "default";
            day.eventText = "";
            day.dayHasEvent = false;
            if(day.weekday == "So" || day.weekday == "Sa"){
                day.backColor = state.settings.BackColorWeekend;
            }
            else{
                day.backColor = state.settings.BackColorNormalDay;               
            }
        }

        
        if(state.settings.showSchulferien == true){
            if(day.shoolholidays !== undefined){
                if(day.shoolholidays.states.includes(state.settings.bundesLaender)){

                    if(day.weekday == "So" || day.weekday == "Sa"){
                        day.backColorWeekDay = state.settings.BackColorWeekend;
                    }
                    else{
                        day.backColorWeekDay = "#3399FF";               
                   }

                    //day.backColorWeekDay =  "#3399FF" ;
                    if(day.dayHasEvent == false){
                        day.backColor =  "#3399FF" ;
                        day.eventText = day.shoolholidays.title;  
                    }
                    day.showTooltip = true;  
                    day.PublicHolidayName = day.shoolholidays.title;  
                }
            }
        }
        
        if(state.settings.showFeiertage == true){
            if(day.publicHolidays !== undefined){
                if(day.publicHolidays.states.includes(state.settings.bundesLaender)){
                    
                    if(day.weekday == "So" || day.weekday == "Sa"){
                        day.backColorWeekDay = state.settings.BackColorWeekend;
                    }
                    else{
                        day.backColorWeekDay = "#33CCFF";               
                   }
                    
                    //day.backColorWeekDay =  "#33CCFF" ;
                    if(day.dayHasEvent == false){
                        day.backColor =  "#33CCFF" ;
                        day.eventText = day.publicHolidays.title;  
                    }
                    day.showTooltip = true;  
                    day.PublicHolidayName = day.publicHolidays.title;  
                }
            }          
        }
        
        
    }

    return{
        collectSpecialHolidays,
        updateCalender,
        updateSingleDay,
        events,
        items
    }
}