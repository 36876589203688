<template>
    <div >
      <div :class="['modal', {'is-active': this.$store.state.settings.startPageOpen}]">
        <div class="modal-background"></div>
        <div class="modal-card"> 

<!-------------------------------------------------------------------------->
<!----------------------------- STARTING INTRO ----------------------------->
<!-------------------------------------------------------------------------->
<section v-if="this.$store.state.settings.introPageToShow == 1" class="modal-card-body" style="  border: 1px solid rgba(0, 0, 0, 0.8);">
<!----------------------------- STARTING TOP ----------------------------->

<div 
style="
    text-align:right;
    padding-right:18px;
    top: 20px;
    bottom:0;
    position:sticky;
    overflow-y:scroll;
    overflow-x:hidden;
    z-index:99;"
    >
      <img src="@/assets/images/close_icon.png" @click="close()" style="width:25px;">
  </div>

  <div>
    <div style="text-align: left; padding-top:0px; padding-left: 35px; ">
      
    </div>
  </div>

  <div class="center logo-top" style="padding-left: 7%;padding-right: 7%;  padding-top:0px; padding-bottom:0px;"><img  src="@/assets/images/annucal_logo.png" ></div>






  <div class="card" style="
        margin-left: 35px;
        margin-right: 35px;
        padding-top:40px;
        ">
  
  <div class="header-top">
  <div style="padding-top: 0px;">Die geniale Art, dein Jahr<span style="color: #0099FF">&nbsp;besser&nbsp;</span>zu <span style="color: #0099FF">planen</span></div></div>
  <img src="@/assets/images/Final_Seite_1.png" class="center" style="padding-left: 30px; padding-right: 30px; padding-top: 20px;">
  <div style="text-align:center; padding-top: 30px; padding-left: 35px; padding-right: 35px; font-weight: 900; font-size: medium; font-family: 'Roboto-light';" >Annucal erleichtert dir die Planung des gesamten Jahres und sorgt dafür, dass du nie den Überblick verlierst.</div>
  <div v-if="!isAuthenticated  == true" style="padding-top:25px; padding-bottom:35px; text-align: center;" ><button class="mybutton1" @click="this.$store.state.settings.introPageToShow = 2">Jetzt loslegen</button></div>
  <div v-if="isAuthenticated  == true" style="padding-top:25px; padding-bottom:35px; text-align: center;" ><button class="mybutton1" @click="close()">Jetzt loslegen</button></div>
</div>
<!-------------------------- CARDS 1 ----------------------------------->
  <div  style="padding-top: 50px; padding-left:25px;padding-right:25px"><cardone  :content=content[0] /></div>
  <div  style="padding-top: 50px; padding-left:25px;padding-right:25px"><cardone  :content=content[1] /></div>
  <div  style="padding-top: 50px; padding-left:25px;padding-right:25px"><cardone  :content=content[2] /></div>
<!-------------------------- START MOVIE ------------------>
  <div class="grid-container-movie" style="padding-top:150px; padding-left:80px; padding-right:80px">
    <div class="grid-item">
      <div class="video-text">
        <div>Wir machen die Jahresplanung für dich einfacher als je zuvor</div>
      </div> 
      <div v-if="!isAuthenticated  == true" style="padding-top:10px; padding-bottom:35px; text-align: left;" ><button class="mybutton1" @click="this.$store.state.settings.introPageToShow = 2">Jetzt loslegen</button></div>
  <div v-if="isAuthenticated  == true" style="padding-top:10px; padding-bottom:35px; text-align: left;"><button class="mybutton1" @click="close()">Jetzt loslegen</button></div>
    <div style="padding-top: 0px; text-align: left;"></div></div><div class="grid-item"><img src="@/assets/images/takeatour.png" style="padding-left: 12;padding-right: 12;align-items:center;justify-content:center;"></div>
  </div>
  
<!-------------------------- CARDS 2 ----------------------------------->
  <div  style="padding-top: 50px; padding-left:25px;padding-right:25px"><cardone  :content=content[3] /></div>
  <div  style="padding-top: 50px; padding-left:25px;padding-right:25px"><cardone  :content=content[4] /></div>
  <div  style="padding-top: 50px; padding-left:25px;padding-right:25px"><cardone  :content=content[5] /></div>
<!-------------------------- END CARDS 2 ----------------------------------->

 <!-------------------------- START FOOTER ----------------------------------->
 <div>
  <my-footer />
</div>
 

</section>
<!----------------------------- ENDING INTRO ------------------------------->


<!-------------------------------------------------------------------------->
<!----------------------------- STARTING REGISTER -------------------------->
<!-------------------------------------------------------------------------->
<section v-if="this.$store.state.settings.introPageToShow == 2" class="modal-card-body" style="border: 1px solid rgba(0, 0, 0, 0.8);
">
  <div style="
     text-align:left;
    padding-left:18px;
    top: 20px;
    bottom:0;
    position:absolute;
    overflow-y:scroll;
    overflow-x:hidden;
    z-index:99;">
    <div 
 
     >
      <img src="@/assets/images/back_arrow_left_icon.png" @click="showstartpage();" style="width:25px;">
    </div>
  </div>

<div  style="
    text-align:right;
    padding-right:18px;
    top: 20px;
    bottom:0;
    position:sticky;
    overflow-y:scroll;
    overflow-x:hidden;
    z-index:98;
    ">
    <div 
  
    >
      <img src="@/assets/images/close_icon.png" @click="close()" style="width:25px;">
  </div>
</div>
  
    
  

  <div class="card reg-pad">

<div v-if="!isAuthenticated && showForgotPassword == false && showRegister==true"> 
          <div style="
                      color:#0b3558;
                      font-size: x-large;
                      font-weight: 600;
                      text-align: left;
                      font-family: 'Roboto-Medium';
                      padding-right:30px;
                      padding-left:20px;
                      padding-top:0px;
                ">
                  Ohne Anmeldung testen
              </div>

              <!----- TEXT 2 ----->
              <div style="
                      color:#0b3558;
                      font-size: large;
                      font-weight: 500;
                      text-align: left;
                      font-family: 'Roboto-Light';
                      padding-right:30px;
                      padding-top:10px;
                      padding-left:20px;
                      
                ">
              Du kannst sofort ohne Anmeldung los legen. In diesem Fall werden deine Eingaben nicht gespeichert.
              So kannst du erst mal in Ruhe testen was Annucal so alles kann
              </div>
              <button
              @click="close()"
                    :disabled="isProcessing"
                    type="button"
                    class="mybutton1"
                    style="margin-top:30px;
                    margin-left:20px;
                  
                    ">
                    Ohne Anmeldung testen
                  </button>

                </div>




    <div class="popoverPanel">
      <div class="popoverArrow" data-popper-arrow></div>

      <!--Forgot Password-->   
       <div v-if="!isAuthenticated && showForgotPassword == true" 
    
      style="
      padding-left:15px;
      padding-right: 15px;
      
      ">
   <div style="
                color:#0b3558;
                font-size: x-large;
                font-weight: 600;
                text-align: left;
                font-family: 'Roboto-Medium';
                padding-right:20px;
                padding-left:0px;
                padding-top:0px;
          " >
                 Passwort vergessen
              </div>
              <div class="cointainer is-fluid has-text-centered">
        <div style="
        padding-top:20px;
        ">
        <form @submit.prevent>
          
            <div class="field">
            <input
              type="email"
              placeholder="Email"
              v-model="forgotPasswordemail"
              id="email"
              class="input is-medium"
              required
            />
        </div>
          
        <div style="text-align: left;">
            <button
              type="submit"
              @click="sendEmail"
              
              class="mybutton1"
              style="
              margin-top: 30px;
              margin-bottom:30px;
              "
            >
            Passwort zurück setzen
            </button>
            </div>
          
          
        </form>
        </div>
           <p style="text-align: end;
           padding-bottom:25px;
           ">
                    <span class="reg-text-1">Noch kein Konto?</span>&nbsp;
                    <span class="reg-text-2"
                    @click="openRegister">
                        Jetzt registrieren
                    </span>
                  </p>
        </div>


      




<!--End Passwrot-->


      </div>
   
      <!--Abmelden-->   
      <div v-if="isAuthenticated && showForgotPassword == false" 
      class="cointainer is-fluid has-text-centered"
      style="
      padding-left:30px;
      padding-right: 30px;
      padding-top:19px;
      
      ">
      <div >
        <div style="
        font-size: large;
        font-weight:600;
      "  is-small>Angemeldet als</div> 
        <div style="
        font-size: large;
        font-weight:400;
      " is-small>{{ user.email }}</div>

<div style="text-align: center;">
          <button
          @click="this.$store.state.settings.startPageOpen = false"
              :disabled="isProcessing"
              type="button"
              class="mybutton1"
              style="
              margin-top: 35px;
              margin-bottom:45px;
      "
            >
              Jetzt loslegen
            </button>
            </div>
      </div>
    
     
      </div>

      <!--Anmelden-->   
      <div style="
        padding-left:0px;
        padding-right: 15px;">
          <div v-if="!isAuthenticated && showForgotPassword == false && showRegister==false">
            <div style="
                color:#0b3558;
                font-size: x-large;
                font-weight: 600;
                text-align: left;
                font-family: 'Roboto-Medium';
                padding-right:30px;
                padding-left:20px;
                padding-top:0px;
          ">
                  Anmelden
              </div>
              <div style="
                color:#0b3558;
                font-size: large;
                font-weight: 500;
                text-align: left;
                font-family: 'Roboto-Light';
                padding-right:30px;
                padding-top:10px;
                padding-left:20px;
                
          ">
                <div >
                  <div >
                    <form>
                      <div class="field">
                        <div class="control">
                          <input
                            v-model="form.email"
                            class="input is-medium"
                            type="email"
                            placeholder="Email"
                            autofocus=""
                            autocomplete="email"
                            style="margin-top:10px">
                        </div>
                      </div>
                      <div class="field">
                        <div class="control">
                          <input
                            v-model="form.password"
                            class="input is-medium"
                            type="password"
                            placeholder="Passwort"
                            autocomplete="current-password"
                            style="margin-top:10px">
                        </div>
                      </div>
                      <div style="text-align: left;">
                      <button
                        @click="login"
                        :disabled="isProcessing"
                        type="button"
                        class="mybutton1"
                        style="
                        margin-top:30px;
                        margin-bottom:30px;
                        ">
                        Anmelden
                      </button>
                      </div>
                    </form>
                  </div>
                  
                  <p style="text-align: end">
                    <span class="reg-text-1">Noch kein Konto?</span>&nbsp;
                    <span class="reg-text-2"
                    @click="openRegister">
                        Jetzt registrieren
                    </span>
                  </p>
                  <p style="text-align: end;
                  padding-bottom: 25px;
                  ">
                    <span class="reg-text-1">Passwort vergessen?</span>&nbsp;
                    <span class="reg-text-2"
                    @click="openForgotPassword">
                          Jetzt zurücksetzen
                    </span>
                  </p>
                </div>
              </div>
          </div>
      </div>

      <!--Register-->
       <div style="
        padding-left:0px;
        padding-right: 15px;">
      <div v-if="!isAuthenticated && showRegister == true">
        <div style="
                color:#0b3558;
                font-size: x-large;
                font-weight: 600;
                text-align: left;
                font-family: 'Roboto-Medium';
                padding-right:30px;
                padding-left:20px;
                padding-top:55px;
          " >
                 Registrieren
              </div>
              <div style="
                color:#0b3558;
                font-size: large;
                font-weight: 500;
                text-align: left;
                font-family: 'Roboto-Light';
                padding-right:30px;
                padding-top:10px;
                padding-left:20px;
                
          ">
        Wenn du dich registrierst werden deine Eingaben gespeichet. So kannst du sofort mit der
        Planung deines Jahres los legen. Die Benutzung von Annucal ist kostenlos. Wenn du dich registrierst
        akzeptierst du unsere allgemeinen Datenschutzbestimmungen 
        </div>
              <div class="cointainer is-fluid has-text-centered">
        <div style="
        padding-left: 20px;
        padding-right: 20px;
        ">
          <form>
            <div class="field">
              <div class="control">
                <input
                  v-model="form.email"
                  class="input is-medium"
                  type="email"
                  placeholder="Email"
                  autofocus=""
                  autocomplete="email"
                  style="margin-top:20px">
              </div>
            </div>
            <!--<div class="field">
              <div class="control">
                <input
                  v-model="form.username"
                  class="input is-small"
                  type="text"
                  placeholder="Username"
                >
              </div>
            </div>-->
            <div class="field">
              <div class="control">
                <input
                  v-model="form.password"
                  class="input is-medium"
                  type="password"
                  placeholder="Passwort"
                  autocomplete="current-password"
                  style="margin-top:10px">
              </div>
            </div>
            <div class="field">
              <div class="control">
                <input
                  v-model="form.passwordConfirmation"
                  class="input is-medium"
                  type="password"
                  placeholder="Passwort wiederholen"
                  style="margin-top:10px"
                >
              </div>
            </div>
            <div style="text-align: left;">
            <button
              @click="register"
              :disabled="isProcessing"
              type="button"
              class="mybutton1"
              style="
              margin-top:30px;
              margin-bottom:30px;
              ">
              Registrieren
            </button>
          </div>
          </form>
          
          <p style="
            text-align: end;
            padding-top: 15px;
            padding-bottom:25px;"
          >
            <span class="reg-text-1">Schon registriert?</span>&nbsp;
            <span  class="reg-text-2" @click="openLogin">Jetzt anmelden</span>
          </p>
                  
         
        </div>
        </div>
      </div>
      </div>
        

      </div>
  </div>
  <!-------------------------- START FOOTER ----------------------------------->
  <my-footer />
   
</section>
<!----------------------------- ENDING REGISTER ----------------------------->


<!----------------------------------------------------------------------------->
<!----------------------------- STARTING DATENSCHUTZ -------------------------->
<!----------------------------------------------------------------------------->
<section v-if="this.$store.state.settings.introPageToShow == 3" class="modal-card-body" style="  border: 1px solid rgba(0, 0, 0, 0.8);">

  <div style="
     text-align:left;
    padding-left:18px;
    top: 20px;
    bottom:0;
    position:absolute;
    overflow-y:scroll;
    overflow-x:hidden;
    z-index:99;">
    <div 
 
     >
      <img src="@/assets/images/back_arrow_left_icon.png" @click="showstartpage()" style="width:25px;">
    </div>
  </div>

<div  style="
    text-align:right;
    padding-right:18px;
    top: 20px;
    bottom:0;
    position:sticky;
    overflow-y:scroll;
    overflow-x:hidden;
    z-index:98;
    ">
    <div 
  
    >
      <img src="@/assets/images/close_icon.png" @click="close()" style="width:25px;">
  </div>
</div>

<img style="
padding-top: 25px;
display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
padding-bottom:15px;
width:150px;

" src="@/assets/images/gdpr.png" alt="GDPR" />
 
<div style="padding-left: 55px; padding-right: 55px;">
<div class="datenschutz-headline1">Ihre Daten sind sicher</div>

<div class="datenschutz-headline2">Höchster Standard nach EU Datenschutz-Grundverordnung</div>
<div class="datenschutz-text" style="padding-bottom:25px">Ihre Daten werden gemäß EU Datenschutz-Grundverordnung verarbeitet. Sie haben immer die volle Kontrolle über Ihre Daten und können diese jederzeit einsehen und bearbeiten. Ihre Daten werden in keinem Fall an Dritte weitergegeben.</div>

<div class="datenschutz-headline2">Ihre Daten im zertifizierten Rechenzentrum</div>
<div class="datenschutz-text" style="padding-bottom:25px">Annucal wird in einem gesicherten Hochleistungsrechenzentrum in Frankfurt betrieben. Ihre Daten bleiben immer im Rechtsraum der Datenschutz-Grundverordnung. Unsere überwachte und vollautomatische Datensicherung schützt Sie jederzeit vor Datenverlust.</div>

<div class="datenschutz-headline3">Datenschutz und Sicherheit</div>

<div class="datenschutz-headline4">Nur sichere Kommunikation</div>
<div class="datenschutz-text" style="padding-bottom:25px">Die Kommunikation zwischen Ihrem Browser und unserem Server ist dank der gesicherten SSL-Kommunikation abhörsicher. Unsere Software zwingt jede Kommunikation stets auf die abhörsichere SSL-Variante. Das erkennen Sie immer an dem https:// in der Adresszeile Ihres Browsers.
Die TLS-Kommunkation entspricht dem Sicherheitsstandard beim Online Banking.</div>

<div class="datenschutz-headline4">Automatische Datensicherung</div>
<div class="datenschutz-text" style="padding-bottom:25px">Wir sichern alle Daten vollständig alle 24 Stunden auf einen gesonderten Server. Selbst im unwahrscheinlichen Fall eines Hardwareausfalls, können die Daten dank der Datensicherung in Kürze wiederhergestellt werden.</div>

<div class="datenschutz-headline4">Ihre Daten im zertifizierten Rechenzentrum</div>
<div class="datenschutz-text" style="padding-bottom:25px">Annucal wird in einem gesicherten Hochleistungsrechenzentrum Frankfurt betrieben. Ihre Daten bleiben immer im Rechtsraum der EU Datenschutzgrundverordnung (DSGVO). Das zertifizierte Rechenzentrum verfügt über höchste Standards zur Ausfall- und Zugangssicherung und wird mit Strom aus erneuerbaren Energien betrieben.</div>

<div class="datenschutz-headline4">Keine Weitergabe der Daten an Dritte</div>
<div class="datenschutz-text" style="padding-bottom:25px">Ihre Daten werden in keinem Fall an Dritte weitergegeben. Die E-Mail-Adresse verwenden wir ausschließlich für die Zwecke der Abwesenheitsverwaltung und geben selbstverständlich auch diese nie an Dritte weiter.</div>

<div class="datenschutz-headline5">Datenschutzhinweise gemäß EU Datenschutz-Grundverordnung</div>
<div class="datenschutz-headline6">Gültig für Nutzer dieser Webseite und Kunden des Dienstanbieters</div>

<div class="datenschutz-headline4">Hinweise zum Datenschutz</div>
<div class="datenschutz-text" style="padding-bottom:25px">Wir sind uns der Bedeutung der personenbezogenen Daten, die Sie uns anvertrauen, bewusst. Wir verstehen es als eine unserer wichtigsten Aufgaben, die Vertraulichkeit Ihrer Daten sicherzustellen. Bei allen Aspekten zum Datenschutz gilt: wir stellen mit annucal.com ein Werkzeug für Sie und Ihr Unternehmen bereit, mit dem Sie Daten eingeben, einsehen, verändern und löschen können. Unsere Leistungen erfordern es nicht, dass wir Ihre Daten durch einen unserer Mitarbeiter einsehen, verändern, bearbeiten oder löschen. Wir kommen mit Ihren Daten also nicht in persönlichen, direkten Kontakt, sondern nur Sie haben die Hoheit über Ihre Daten.
Im folgenden können Sie erfahren, welche Maßnahmen wir aufgesetzt haben, um Ihre Privatsphäre zu schützen.</div>

<div class="datenschutz-headline4">Wer ist für die Datenverarbeitung verantwortlich und an wen können Sie sich wenden?</div>
<div class="datenschutz-text" style="padding-bottom:25px">Für den Datenschutz verantwortlich ist der Anbieter des Angebots dieser Webseiten (nachfolgend "Anbieter" oder "wir / uns" bezeichnet):</div>
<div class="datenschutz-text" style="padding-bottom:25px">Der Anbieter stellt eine über das Internet auf www.annucal.com erreichbare Software (nachfolgend "Annucal" oder "Software" bezeichnet) bereit. In allen Fragen zum Datenschutz erreichen Sie uns wie folgt:</div>

<div class="datenschutz-text" style="padding-bottom:25px">Annucal<br/>Lerchenstr. 28<br/>22767 Hamburg<br/>Deutschland</div>
<div class="datenschutz-text" style="padding-bottom:25px">Lerchenstr. 28<br/>Email: info@annucal.com<br/>Kontakt: Kontaktformular</div>

<div class="datenschutz-headline4">Welche Quellen und Daten werden genutzt?</div>
<div class="datenschutz-text" style="padding-bottom:25px">Wir stellen mit Annucal ein Werkzeug zur Eingabe, Bearbeitung, Verwaltung und Nachvollziehbarkeit von Jahresplanungen zur Verfügung. Die Software erlaubt Einstellungsmöglichkeiten, wie die Anzeige von Feiertagen und Schulferien und mehr.
Die Nutzer von Annucal können gemäß den Nutzungsbedingungen persönliche Benutzerkonten einrichten, deren Daten von Annucal automatisiert verarbeitet und verwaltet werden.
Somit verarbeiten wir personenbezogene Daten, die wir im Rahmen der Nutzung von Annucal erhalten. Die Daten werden über die von Annucal zur Verfügung gestellten Eingabemasken eingeben und von Annucal automatisiert verarbeitet ohne Einfluss- oder Einsichtnahme durch einen unserer Mitarbeiter.
Relevante personenbezogene Daten, die wir im Rahmen der Nutzung von annucal.com erhalten, können sein: Name, Emailadresse, bevorzugte Sprache für die Verwendung der Software, Urlaubsdaten, Feiertagsregelung, individuelle Datenfelder in der digitalen Personalakte.</div>

<div class="datenschutz-headline4">Wie kann die Abfrage persönlicher Daten festgestellt werden (Transparenz)?</div>
<div class="datenschutz-text" style="padding-bottom:25px">Bei der Nutzung von Annucal wird der Nutzer darüber informiert, welche Daten im Einzelfall notwendig sind. Wenn ein Nutzer beispielsweise ein Nutzerkonto im Annucal erstellen möchte, weist Annucal auf die einzutragenden persönlichen Daten hin, in diesem Fall unter anderem der Name und die E-Mail-Adresse.</div>

<div class="datenschutz-headline4">Wofür werden Ihre Daten verarbeitet (Zweck der Verarbeitung) und auf welcher Rechtsgrundlage?</div>
<div class="datenschutz-text" style="padding-bottom:25px">Wir verarbeiten die vorab genannten personenbezogenen Daten im Einklang mit den Bestimmungen der EU Datenschutz-Grundverordnung (DSGVO) und dem Bundesdatenschutzgesetz (BDSG):</div>

<div class="datenschutz-headline6">1. Zur Erfüllung von vertraglichen Pflichten (Artikel 6 Abs.1 b DSGVO)</div>
<div class="datenschutz-text" style="padding-bottom:25px">Die Verarbeitung personenbezogener Daten erfolgt zur Erbringung der Dienstleistung im Rahmen der Nutzung von Annucal oder zur Durchführung von Maßnahmen, die auf Ihre Anfrage hin erfolgen.</div>

<div class="datenschutz-headline6">2. Im Rahmen der Interessenabwägung (Artikel 6 Abs. 1 f DSGVO)</div>
<div class="datenschutz-text" style="padding-bottom:25px">- Gewährleistung der IT-Sicherheit und des IT-Betriebs
- Zur Geltendmachung rechtlicher Ansprüche und Verteidigung bei rechtlichen Streitigkeiten
- Maßnahmen zur geschäftlichen Steuerung und Ergänzung und Entwicklung von Dienstleistungen und Produkten</div>

<div class="datenschutz-headline4">Werden Daten und E-Mail-Adressen für Werbezwecke verwendet?</div>
<div class="datenschutz-text" style="padding-bottom:25px">Ihre Daten werden ausschließlich für die Erfüllung der Funktionalitäten im Annucal verwendet. Die Daten werden nicht für Werbezwecke verwendet. E-Mail-Adressen werden nicht an Dritte weitergegeben. Wir verschicken keine Werbung an die E-Mail-Adressen. Die E-Mail-Adressen werden nur zum Versand von E-Mails im Zusammenhang mit den Funktionalitäten von Annucal.</div>

<div class="datenschutz-headline4">Wer erhält die Daten?</div>
<div class="datenschutz-text" style="padding-bottom:25px">Die Daten werden vom Nutzer in Annucal eingegeben, bearbeitet, verändert oder gelöscht. Die Mitarbeiter des Anbieters haben technisch die Möglichkeit, die eingegebenen Daten einzusehen, zu ändern oder zu löschen, greifen jedoch ohne eine ausdrückliche und vorangehende Beauftragung durch den Nutzer nicht auf die Daten zu. Eine Beauftragung durch den Nutzer zur Einsicht, Änderung, Hinzufügen oder Löschen von Daten kann beispielsweise in folgenden Fällen gegeben sein:
- Bei Supportbedarf des Nutzers zur Klärung von offenen Fragen zu der Funktionsweise von Annucal
- Bei einer konkreten Beauftragung des Nutzers für die Änderung bestimmter Daten
- Zur Auskunft über die gespeicherten persönlichen Daten eines Nutzers</div>

<div class="datenschutz-headline4">Werden Daten an Dritte übermittelt?</div>
<div class="datenschutz-text" style="padding-bottom:25px">Eine Datenübermittlung an Dritte findet wie folgt statt:

- Annucal wird auf Servern in Frankfurt betrieben. Die Server stellen lediglich die Infrastruktur wie Rechnerkapazität, Internetanbindung und Speicherpatz zur Verfügung. Auf persönliche Daten wird nicht zugegriffen.
- Annucal verwendet das Single Sign On Feature (im Folgenden "SSO") von Drittanbietern für die automatische Anmeldung am Annucal Nutzerkonto. Wenn der Nutzer sein Annucal Nutzerkonto mit dem Nutzerkonto eines der SSO-Anbieter verknüpft, dann wird auf der Seite für die Anmeldung am Annucal Nutzerkonto eine Verbindung zu dem Server des SSO-Anbieters hergestellt. Der Annucal Nutzer kann entscheiden, ob das SSO aktiviert verwendet werden soll und ob und an welchen Anbieter Daten übertragen werden sollen. Die Datenübertragung findet nur statt, wenn der Annucal Nutzer der Übertragung zuvor zugestimmt hat. Die SSO-Anbieter und die Links zu den Datenschutzerklärungen der SSO-Anbieter lauten:
- Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (https://www.google.com/intl/de/policies/privacy/</div>

<div class="datenschutz-headline4">Werden Daten in ein Drittland oder an eine internationale Organisation übermittelt?</div>
<div class="datenschutz-text" style="padding-bottom:25px">Eine Datenübermittlung in Länder außerhalb der EU bzw. des EWR (sogenannte Drittstaaten) findet mit Ausnahme der oben genannten Empfänger, für die jeder Nutzer vorab seine Zustimmung erteilen muss, nicht statt.</div>

<div class="datenschutz-headline4">Wie lange werden die Daten gespeichert?</div>
<div class="datenschutz-text" style="padding-bottom:25px">Wir verarbeiten und speichern Ihre personenbezogenen Daten, solange Sie Annucal nutzen.
Sie haben jederzeit die Möglichkeit, Ihre Daten im Annucal selbstätig und ohne unsere Mithilfe zu löschen.
Zur Datensicherheit und Sicherstellung der Wiederherstellbarkeit im Falle eines schwerwiegenden Serverausfalles, erstellen wir automatisiert Datensicherungen aller Daten. Die maximale Aufbewahrungsdauer für die Datensicherungen beträgt 14 Kalendertage. Nach diesem Zeitraum werden die Datensicherungen automatisiert gelöscht.</div>

<div class="datenschutz-headline4">Welche Datenschutzrechte haben Sie?</div>
<div class="datenschutz-text" style="padding-bottom:25px">Jede betroffene Person hat das Recht auf Auskunft nach Artikel 15 DSGVO (EU Datenschutz Grundverordnung), das Recht auf Berichtigung nach Artikel 16 DSGVO, das Recht auf Löschung nach Artikel 17 DSGVO, das Recht auf Einschränkung der Verarbeitung nach Artikel 18 DSGVO, das Recht auf Widerspruch aus Artikel 21 DSGVO sowie das Recht auf Datenübertragbarkeit aus Artikel 20 DSGVO. Beim Auskunftsrecht und beim Löschungsrecht gelten die Einschränkungen nach §§ 34 und 35 BDSG. Darüber hinaus besteht ein Beschwerderecht bei einer Datenschutzaufsichtsbehörde (Artikel 77 DSGVO i. V. m § 19 BDSG).
Eine erteilte Einwilligung in die Verarbeitung personenbezogener Daten können Sie jederzeit uns gegenüber widerrufen. Dies gilt auch für den Widerruf von Einwilligungserklärungen, die vor der Geltung der EU-Datenschutz-Grundverordnung, also vor dem 25. Mai 2018, uns gegenüber erteilt worden sind.</div>

<div class="datenschutz-headline4">Gibt es eine Pflicht zur Bereitstellung von Daten?</div>
<div class="datenschutz-text" style="padding-bottom:25px">Die Eingabe der Daten im Annucal erfolgt freiwillig und ohne Pflicht. Sie entscheiden selbst, welche Daten Sie im Rahmen der Nutzung von Annucal bereitstellen. Aus technischen und funkionellen Gründen ist in manchen Fällen die Angabe von Daten erforderlich, um Annucal nutzen zu können. Annucal weist darauf hin, wenn für die Eintragung eines Datensatzes oder das Ausführen einer Funktion weitere Daten notwendig sind.</div>

<div class="datenschutz-headline4">Werden technische Daten gesammelt (Server-Protokolldaten)?</div>
<div class="datenschutz-text" style="padding-bottom:25px">Annucal zeichnet automatisch Informationen auf, die Ihr Browser beim Besuch einer Website sendet. Diese Serverprotokolle können folgende Informationen enthalten: Ihre Webanfrage, Ihre IP-Adresse, den Browsertyp, die Sprache des Browsers, Datum und Zeitpunkt Ihrer Anfrage sowie ein oder mehrere Cookies, die Ihren Browser eindeutig identifizieren.</div>

<div class="datenschutz-headline4">Werden Cookies verwendet?</div>
<div class="datenschutz-text" style="padding-bottom:25px">Ein Cookie ist ein kleines Informationspaket, das zwischen Ihrem Browser und einem zur Bereitstellung einer Internetseite eingesetzten Internet-Server übertragen wird. Cookies werden auch bei der Nutzung von Annucal übertragen. Es werden Cookies gespeichert und übertragen, die für den einwandfreien technischen Betrieb von Annucal erforderlich sind.
Die Cookies dienen beispielsweise dazu, Ihre Anmeldung am Annucal zu speichern. Ohne Cookies kann Ihre Anmeldung nicht gespeichert werden und Sie werden umgehend nach der Anmeldung automatisch wieder abgemeldet, so dass eine Nutzung von Annucal nicht möglich wäre.</div>

<div class="datenschutz-headline4">Wie erfolgt die Einwilligung in die Verarbeitung personenbezogener Daten?</div>
<div class="datenschutz-text" style="padding-bottom:25px">Mit der Benutzung von Annucal und insbesondere bei der Registrierung eines neuen Benutzers willigen Sie in die Verarbeitung der personenbezogenen Daten gemäß diesen Datenschutzbestimmungen ein.</div>

<div class="datenschutz-headline4">Information über Ihr Widerspruchsrecht nach Artikel 21 EU-Datenschutz- Grundverordnung (DSGVO)</div>
<div class="datenschutz-text" style="padding-bottom:55px">Einzelfallbezogenes Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Artikel 6 Abs. 1 e DSGVO (Datenverarbeitung im öffentlichen Interesse) und Artikel 6 Abs. 1 f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling im Sinne von Artikel 4 Abs. 4 DSGVO. Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
Der Widerspruch kann formfrei per E-Mail oder über unser Kontaktformular oder an die oben auf dieser Seite angegebene postalische Adresse erfolgen.</div>

</div>

<my-footer />

</section>
<!------------------------------- ENDING DATENSCHUTZ -------------------------->


<!-------------------------------------------------------------------------->
<!----------------------------- STARTING ABOUT US -------------------------->
<!-------------------------------------------------------------------------->
<section v-if="this.$store.state.settings.introPageToShow == 4" class="modal-card-body" style="border: 1px solid rgba(0, 0, 0, 0.8);
">
  <div style="
     text-align:left;
    padding-left:18px;
    top: 20px;
    bottom:0;
    position:absolute;
    overflow-y:scroll;
    overflow-x:hidden;
    z-index:99;">
    <div 
 
     >
      <img src="@/assets/images/back_arrow_left_icon.png" @click="showstartpage();" style="width:25px;">
    </div>
  </div>

<div  style="
    text-align:right;
    padding-right:18px;
    top: 20px;
    bottom:0;
    position:sticky;
    overflow-y:scroll;
    overflow-x:hidden;
    z-index:98;
    ">
    <div 
  
    >
      <img src="@/assets/images/close_icon.png" @click="close()" style="width:25px;">
  </div>
</div>
  
    
  

  <div  style="
  margin-top:25px;
           margin-left: 45px;
        margin-right: 45px;
        margin-bottom: 179px;
        padding-left:25px;
        padding-right:25px;
        padding-top:25px;
        ">

<div > 
  <div style="color:#0b3558;font-size: xx-large;font-weight: 500;text-align: left; font-family: 'Roboto-Black';">
  <div style="padding-top: 0px;">Besser <span style="color: #0099FF">planen</span></div></div>

              <!----- TEXT 2 ----->
              <div style="
           color:#0b3558;
      font-size: medium;
      font-weight: 600;
      text-align: left;
      font-family: 'Roboto-Light';
      padding-right:15px;
      padding-top:25px;
      padding-bottom:35px;  
        ">
     Annucal ist eine effektive und intuitive Planungsplattform, die dir dabei hilft dein Jahr
besser zu planen. Noch ist nicht alles perfekt, aber wir arbeiten jeden Tag daran, dass
es das wird.
Neben der Optimierung von vorhandenen Funktionen, planen wir eine Vielzahl an
weiteren Anwendungen. Dabei hoffen wir auf deine Unterstützung und freuen uns über
jede Art von Feedback. 
              </div>
      

                </div>




    <div class="popoverPanel">
      <div class="popoverArrow" data-popper-arrow></div>

     
        

      </div>
  </div>
  <!-------------------------- START FOOTER ----------------------------------->
  <my-footer />
   
</section>
<!----------------------------- ENDING ABOUT US ----------------------------->


<!-------------------------------------------------------------------------->
<!----------------------------- STARTING IMPRESSUM -------------------------->
<!-------------------------------------------------------------------------->
<section v-if="this.$store.state.settings.introPageToShow == 5" class="modal-card-body" style="border: 1px solid rgba(0, 0, 0, 0.8);
">
  <div style="
     text-align:left;
    padding-left:18px;
    top: 20px;
    bottom:0;
    position:absolute;
    overflow-y:scroll;
    overflow-x:hidden;
    z-index:99;">
    <div 
 
     >
      <img src="@/assets/images/back_arrow_left_icon.png" @click="showstartpage();" style="width:25px;">
    </div>
  </div>

<div  style="
    text-align:right;
    padding-right:18px;
    top: 20px;
    bottom:0;
    position:sticky;
    overflow-y:scroll;
    overflow-x:hidden;
    z-index:98;
    ">
    <div 
  
    >
      <img src="@/assets/images/close_icon.png" @click="close()" style="width:25px;">
  </div>
</div>
  
    
  

  <div  style="
  margin-top:25px;
           margin-left: 45px;
        margin-right: 45px;
        padding-left:25px;
        padding-right:25px;
        padding-top:25px;
        ">

<div > 
  <div style="color:#0b3558;font-size: xx-large;font-weight: 500;text-align: left; font-family: 'Roboto-Black';">
  <div style="padding-top: 0px;">Impressum</div></div>

              <!----- TEXT 2 ----->
              <div style="
           color:#0b3558;
      font-size: medium;
      font-weight: 600;
      text-align: left;
      font-family: 'Roboto-Light';
      padding-right:15px;
      padding-top:25px;
      padding-bottom:35px;  
        ">
            <p>Annucal</p> 
            <p>Inh. Jan-Willem Wulff</p>
            <p>Lerchenstr. 28</p>
            <p>22767 Hamburg</p>
            <p>Deutschland</p>
            <br />
            <p>Tel.: 040-50719309</p>
            <p>E-Mail: info@annucal.com</p>
            <br />
            <p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE813291187</p>
            <br />
            <p>Plattform der EU-Kommission zur Online-Streitbeilegung: https://ec.europa.eu/consumers/odr</p>
            <br />
            <p>Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle nicht verpflichtet, hierzu jedoch grundsätzlich bereit.</p>
            
            
          </div>
      

                </div>




    <div class="popoverPanel">
      <div class="popoverArrow" data-popper-arrow></div>

     
        

      </div>
  </div>
  <!-------------------------- START FOOTER ----------------------------------->
  <my-footer />
   
</section>
<!----------------------------- ENDING IMPRESSUM ----------------------------->



<!-------------------------------------------------------------------------->
<!----------------------------- STARTING CONTACT FORM -------------------------->
<!-------------------------------------------------------------------------->
<section v-if="this.$store.state.settings.introPageToShow == 6" class="modal-card-body" style="border: 1px solid rgba(0, 0, 0, 0.8);
">
  <div style="
     text-align:left;
    padding-left:18px;
    top: 20px;
    bottom:0;
    position:absolute;
    overflow-y:scroll;
    overflow-x:hidden;
    z-index:99;">
    <div 
 
     >
      <img src="@/assets/images/back_arrow_left_icon.png" @click="showstartpage();" style="width:25px;">
    </div>
  </div>

<div  style="
    text-align:right;
    padding-right:18px;
    top: 20px;
    bottom:0;
    position:sticky;
    overflow-y:scroll;
    overflow-x:hidden;
    z-index:98;
    ">
    <div 
  
    >
      <img src="@/assets/images/close_icon.png" @click="close()" style="width:25px;">
  </div>
</div>
  
    
  

  <div style="
  margin-top:25px;
           margin-left: 45px;
        margin-right: 45px;
        margin-bottom: 131px;
        padding-left:25px;
        padding-right:25px;
        padding-top:25px;
        ">

<div > 
  <div style="color:#0b3558;font-size: xx-large;font-weight: 500;text-align: left; font-family: 'Roboto-Black';">
  <div style="padding-top: 0px;">Kontakt</div> </div>

 <contact-form />
      

                </div>




    <div class="popoverPanel">
      <div class="popoverArrow" data-popper-arrow></div>

     
        

      </div>
  </div>
  <!-------------------------- START FOOTER ----------------------------------->
  <my-footer />
   
</section>
<!----------------------------- ENDING CONTACT FORM ----------------------------->

  </div>
  </div>
  </div>

</template>
  
  
<style scoped>

.top-grid{
    display: grid;
    grid-template-columns: 50% 50%;
    
    position:sticky;
    overflow-y:scroll;
    overflow-x:hidden;
    z-index:99;
}

.navbar-top {
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  position: sticky;
 margin-top:0px;
 height: 100px;
 
}

.navbar-top a {
  float: left;
  display: block;
  color: #f2f2f2;


}

.gridmenue {
    display: grid;
    grid-template-columns: 25% 50% 25% ;
}

#gridfooter {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        grid-gap: 0px;
}

#gridfooter > div {
        font-size: 30px;
        padding-top:25px;
        color: #0b3558;
        background: #ffffff;
        text-align: left;
}

.grid-item-footer-text0{
  text-align: left;
  font-weight: 900;
  text-align: left;
  font-family: 'Roboto-Black';
  font-size: x-large;
}

.grid-item-footer-text1{
  text-align: left;
  font-weight: 900;
  text-align: left;
  font-family: 'Roboto-Black';
  font-size: medium;
}

.datenschutz-headline1{
  text-align: center;
  font-weight: 600;
  font-family: 'Roboto-Medium';
  font-size: xx-large;
  margin-top:20px;
  padding-bottom: 45px;
  color:#0b3558;
}

.datenschutz-headline2{
  text-align: left;
  font-weight: 600;
  font-family: 'Roboto-Medium';
  font-size: large;
  color:#0b3558;
}

.datenschutz-headline3{
  text-align: left;
  font-weight: 600;
  font-family: 'Roboto-Medium';
  font-size: xx-large;
  padding-top: 25px;
  padding-bottom: 15px;
  color:#0b3558;
  
}

.datenschutz-headline4{
  text-align: left;
  font-weight: 400;
  font-family: 'Roboto-Medium';
  font-size: large;
  color:#0b3558;
}

.datenschutz-headline5{
  text-align: left;
  font-weight: 300;
  font-family: 'Roboto-Medium';
  font-size: xx-large;
  padding-top: 45px;
  padding-bottom: 15px;
  color:#0b3558;
}
.datenschutz-headline6{
  text-align: left;
  font-weight: 700;
  font-family: 'Roboto-Medium';
  font-size: small;
  padding-bottom: 15px;
  color:#696969;
}

.datenschutz-headline7{
  text-align: left;
  font-weight: 600;
  font-family: 'Roboto-Medium';
  font-size: xx-large;
  color:#0b3558;
}

.datenschutz-text{
  text-align: left;
  font-weight: 600;
  font-family: 'Roboto-light';
  font-size: small;
  color:#0b3558;
}

.grid-item-footer-text2{
  text-align: left;
  font-weight: 400;
  font-family: 'Roboto-light';
  font-size: small;
}

@media (min-width: 100px){

  .reg-pad{
    margin-top:25px;
           margin-left: 15px;
        margin-right: 15px;
        padding-left:10px;
        padding-right:10px;
        padding-top:25px;
  }
  .reg-text-1{
      color:#0b3558;
      font-size: small;
      font-weight: 500;
      text-align: left;
      font-family: 'Roboto-Light';
      padding-top:10px;
      padding-left:20px;
  }
  .reg-text-2{
      color:#0b3558;
      font-size: small;
      font-weight: 500;
      font-family: 'Roboto-Medium';
      text-align: left;
      padding-right:0px;
      padding-top:10px;
      padding-left:0px;       
  }
  .logo-top{
  height:50px;
}
  .video-text{
    color:#0b3558;
    font-size: large; 
    padding-top:0px; 
    padding-right: 1px; 
    font-weight: 400;text-align: left;
    font-family: 'Roboto-Black';
  }
  .header-top{
    color:#0b3558;
    font-size: x-large;
    font-weight: 500;
    text-align: center; 
    font-family: 'Roboto-Black';
  }

}

@media (min-width: 1200px){

  .reg-pad{
    margin-top:25px;
           margin-left: 45px;
        margin-right: 45px;
        padding-left:25px;
        padding-right:25px;
        padding-top:25px;
  }
  .reg-text-1{
      color:#0b3558;
      font-size: medium;
      font-weight: 500;
      text-align: left;
      font-family: 'Roboto-Light';
      padding-top:10px;
      padding-left:20px;
  }
  .reg-text-2{
      color:#0b3558;
      font-size: medium;
      font-weight: 500;
      font-family: 'Roboto-Medium';
      text-align: left;
      padding-right:0px;
      padding-top:10px;
      padding-left:0px;       
  }
  .logo-top{
  height:70px;
}
  .video-text{
    color:#0b3558;
    font-size: x-large; 
    padding-top:20px; 
    padding-right: 15px; 
    font-weight: 400;text-align: left;
    font-family: 'Roboto-Black'; 
  }

  .header-top{
    color:#0b3558;
    font-size: xx-large;
    font-weight: 500;
    text-align: center; 
    font-family: 'Roboto-Black';
  }

}



.center {
  display: flex;
justify-content: center;
}

.grid-container-movie {
    
    display: grid;
    grid-template-columns: 47% 53%;
    background-color: #ffffff;
    padding-left: 25px;
    padding-right: 20px;
    gap: 1rem;
  }
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 30px;
  padding-left: 0px;
  text-align: center;
}

.mybutton1 {
  background-color: #0099FF; 
  border: none;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 8px;
  padding-top: 7px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: 'Roboto-Black';
  font-size: large;
  font-weight: 500;
  cursor: pointer;
  border-radius: 23px;
}

</style>

<script>
    import Cardone from "./cardone.vue"
    import { defineComponent, ref, computed } from 'vue'
    import { createPopper } from '@popperjs/core'
    import calenderCore from "../composition/calenderCore";
    import useAuth from '../composition/useAuth';
    import { useStore } from "vuex";
    import { getAuth, sendPasswordResetEmail } from "firebase/auth";
    import { useToast } from "vue-toastification"
    import MyFooter from "../components/MyFooter.vue"
    import ContactForm from "../components/ContactForm.vue"
    const toast = useToast();

    export default defineComponent ({
      
     
      data () {
        return {
          isOpen: true,
          //register start
          form: {
        email: "",
        password: "",
      },
      message: null,
      error: null,
      emailSending: false,
      forgotPasswordemail: "",
      useAuth,
      showForgotPassword: false,
      showRegister: true
        }
      },
      computed:{
        content(){
            return this.$store.state.content.items;
        },
        content2(){
            return this.$store.state.loungecontentfirst.items;
        }
      },

      components:{
        Cardone,
            MyFooter,
            ContactForm
      },

      methods: {
        updateProfile() {
          this.$store.dispatch("user/updateProfile", {
            data: this.userProfile,
            onSuccess: () => this.isOpen = false
          })
        },

        // REGISTER START

      async register() {
        if(this.form.password != this.form.passwordConfirmation){
          toast.error("Die Passwörter stimmen nicht überein"); 
        }
        else{
          await this.$store.dispatch("user/register", this.form)
          this.$store.state.settings.Authenticated = true
        }
      },

    sendEmail() {
      
        if (!this.forgotPasswordemail) {
          this.error = "Please type in a valid email address.";
          return;
        }
        this.error = null;
        this.message = null;
        this.emailSending = true;
        
          sendPasswordResetEmail(getAuth(),this.forgotPasswordemail)
          .then(() => {
            this.emailSending = false;
            //this.message = "Eine Email zum zurück setzen Deines Passworts wurde versendet";
            toast.success("Eine Email zum zurück setzen deines Passworts wurde versendet")
          })
          .catch(error => {
            this.emailSending = false;
            if(error.message.includes ("invalid-email")){
              toast.error("Die Emailadresse scheint nicht korrekt zu sein"); 
                //this.error = "Die Emailadresse scheint nicht korrekt zu sein"
                //dispatch("toast/error", "Die Emailadresse scheint nicht korrekt zu sein", {root: true});
            }
            else{
                //this.error = "Ein Fehler ist aufgetreten. Bitte versuche es noch einmal" //error.message;
                //store.dispatch("toast/error", "Ein Fehler ist aufgetreten. Bitte versuche es noch einmal", {root: true});
                toast.error(error)
            }
            
          });
      },


    async login() {
      await this.$store.dispatch("user/login", this.form);
      if (this.isAuthenticated){
        this.$router
          .push({ path: '/' })
          .then(() => { this.$router.go() })
      }
    },

    resetVariables(){
      this.showForgotPassword = false
    }
      },

      watch: {
    isAuthenticated(isAuth) {
      if (isAuth) { 
        this.$router.push("/");
      }
    }
  },

  setup(props) {
    const button = ref(null);
    const popover = ref(null);
    const toggle = ref(false);
    const calenderCore1 = calenderCore();
    const store = useStore();
    const { state } = store;
    
    
    const isProcessing = computed(() => state.user.auth.isProcessing);
    const isAuthenticated = computed(() => store.getters["user/isAuthenticated"]);
    const user = computed(() => state.user.data);

    const popperInstance = computed(() => {
      return createPopper(button.value, popover.value, {
        placement: props.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
        ],
        strategy: 'absolute'
      })
    })

    const insertElement = (btn, tip) => {
      button.value = btn
      popover.value = tip
    }

    const handleShow = (e) => {
      if(button.value === null && popover.value === null) {
        insertElement(e.target, e.target.parentElement.querySelector('.popoverPanel'))
      }
    }

    const handleClick = (e) => {
      if(button.value === null && popover.value === null) {
        insertElement(e.target, e.target.parentElement.querySelector('.popoverPanel'))
      }
      popover.value.setAttribute('data-show', '')
      popperInstance.value.update()
      toggle.value = true
    }

    const handleHide = (e) => {
      
      if(button.value === null && popover.value === null) {
        insertElement(e.target, e.target.parentElement.querySelector('.popoverPanel'))
      }
      
      popover.value.removeAttribute('data-show');
      toggle.value = false
      
    }

    function bundeslaenderClicked(bundesLand){
      this.$store.state.settings.bundesLaender = bundesLand;
      calenderCore1.updateCalender();
    }

    function checkBoxClicked(checkBoxName){
      
      var checkBox = document.getElementById(checkBoxName);
      if (checkBox.checked == true){
        checkBox.checked = false;
        switch(checkBoxName) {
        case 'togglekw':
          this.$store.state.settings.showKalenderwochen = false;
          break;
        case 'toggleshoolholiday':
          this.$store.state.settings.showSchulferien = false;
          calenderCore1.updateCalender();
          break;
        case 'toggleholidays':
          this.$store.state.settings.showFeiertage = false;
          calenderCore1.updateCalender();
          break;
        default:
          // code block
      }
      } else {
        checkBox.checked = true;
        switch(checkBoxName) {
        case 'togglekw':
          this.$store.state.settings.showKalenderwochen = true;
          break;
        case 'toggleshoolholiday':
          this.$store.state.settings.showSchulferien = true;
          calenderCore1.updateCalender();
          break;
        case 'toggleholidays':
          this.$store.state.settings.showFeiertage = true;
          calenderCore1.updateCalender();
          break;
        default:
          // code block
      }
      }
    }

    function openForgotPassword(){
      this.showForgotPassword = true
      this.showRegister = false
    }

    function openRegister(){
      this.showRegister = true
      this.showForgotPassword = false
    }

    function openLogin(){
      this.showForgotPassword = false
      this.showRegister = false
    }

    function logout(){
      this.$store.dispatch('user/logout')
      console.log("Logout")
      this.$router
      .push({ path: '/' })
      .then(() => { this.$router.go() })
    }

    function close(){
      this.$store.state.settings.introPageToShow = 2
      this.$store.state.settings.startPageOpen = false
    }

    function showstartpage(){
      this.$store.state.settings.introPageToShow = 1;
    }

    return {
      toggle,
      handleShow,
      handleClick,
      handleHide,
      checkBoxClicked,
      bundeslaenderClicked,
      close,
      showstartpage,
      isAuthenticated,
      user,
      openForgotPassword,
      openRegister,
      openLogin,
      isProcessing,
      calenderCore1 : calenderCore(),
      logout

    }
  },
    })
  </script>